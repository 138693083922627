<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
        >
          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">Accepteer voorwaarden</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <p>
                      Voor de diensten van Stichting Digitaal Informatieplatform Podiummkunsten (DIP) zijn de
                      gebruiksvoorwaarden op
                      <a
                        href="https://dip.nl/voorwaarden"
                        target="_blank"
                        >www.dip.nl/voorwaarden</a
                      >
                      van toepassing. Om gebruik te mmaken van deze applicatie dient u hieronder eenmalig de voorwaarden
                      te accepteren.
                    </p>
                    <v-row>
                      <!-- voorwaarden -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="ik heb de algemene voorwaarden gelezen en ga hiermee akkoord"
                          :rules="formValidationRules.required"
                          v-model="acceptForm.accept"
                          class="checkbox primary--text ma-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
import ApiService from '../../services/api.service';
import router from '../../router/router.js';
import functions from '../../services/functions.service';
import { TokenService } from '../../services/storage.service';
import UserService from '../../services/user.service';

export default {
  name: 'acceptTerms',
  data() {
    return {
      formValid: false,
      formError: '',
      formLoading: false,
      acceptForm: {
        accept: '',
      },
      formValidationRules: {},
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.acceptForm;

        if (form.accept) {
          ApiService.post('/Account/AcceptTerms')
            .then((response) => {
              TokenService.saveToken(response.data.token);
              ApiService.setHeader();

              if (response.data.success) {
                UserService.setNavigation();
                setTimeout(function () {
                  router.push('/dashboard');
                }, 1000);
              } else {
                this.formError =
                  'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
                this.formLoading = false;
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error);
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            });
        } else {
          this.formError = 'Accepteer de voorwaarden';
          this.formLoading = false;
        }
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>