<template>
  <v-container
    v-if="!authenticating"
    class="d-flex flex-column align-center justify-center"
    style="min-height: 100vh"
  >
    <v-row class="d-flex flex-column align-center justify-center pa-5">
      <v-col>
        <v-card
          class="d-flex"
          flat
        >
          <v-container
            pa-12
            pt-12
            class="d-flex flex-column align-center"
          >
            <template v-if="loggedIn">
              <h1 class="text-center primary--text mb-3">
                Helaas deze pagina is niet gevonden,<br />
                mogelijk heb je hier geen rechten voor.
              </h1>
              <p class="text-center primary--text mb-8">Vragen over je rechten neem contact op met DIP.</p>
              <v-btn
                rounded
                x-large
                elevation="0"
                color="primary"
                href="https://dip.nl/contact"
                target="_blank"
                class="text-lowercase font-weight-bold"
              >
                contact
              </v-btn>
            </template>
            <template v-else>
              <h1 class="text-center primary--text mb-3">Helaas deze pagina is niet gevonden.</h1>
              <p class="text-center primary--text mb-8">Login en probeer het nog eens.</p>
              <v-btn
                rounded
                x-large
                elevation="0"
                color="primary"
                :to="{ name: 'login' }"
                class="text-lowercase font-weight-bold"
              >
                login
              </v-btn>
            </template>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'PageNotFound',
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapState('auth', ['loggedIn', 'authenticating']),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>