<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="17"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    v-model="formValid"
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h1 class="primary--text mb-5">{{ this.producername }}</h1>
                    <h4 class="primary--text mb-2 font-weight-regular">gegevens producent</h4>

                    <v-card
                      class="pa-6 mb-10"
                      flat
                    >
                      <!-- Name -->
                      <v-row class="mt-0">
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-text-field
                            class="primary--text"
                            label="naam"
                            v-model="editProducerForm.name"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- Type -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="type"
                            v-model="editProducerForm.type"
                            :items="producerTypeOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <!-- Branchevereniging bijwerken -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="selecteer branchevereniging"
                            v-model="editProducerForm.branchAssociationId"
                            :items="branchAssociations"
                            outlined
                            clearable
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <!-- Voorwaarden DIP geaccepteerd -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="voorwaarden DIP geaccepteerd"
                            v-model="editProducerForm.agreedToTerms"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <!-- Publieksmonitor verbergen -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="toon publieksmonitor"
                            v-model="editProducerForm.hasPublicMonitorAccess"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card>

                    <h4 class="primary--text mb-2 font-weight-regular">adres</h4>
                    <v-card
                      class="pa-6 mb-1"
                      flat
                    >
                      <v-row class="mt-0">
                        <!-- Straat -->
                        <v-col
                          cols="8"
                          class="py-0"
                        >
                          <v-text-field
                            label="straat"
                            v-model="editProducerForm.street"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="4"
                          class="py-0"
                        >
                          <!-- Nummer -->
                          <v-text-field
                            label="huisnummer"
                            v-model="editProducerForm.number"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="4"
                          class="py-0"
                        >
                          <!-- Postcode -->
                          <v-text-field
                            label="postcode"
                            v-model="editProducerForm.zipCode"
                            :rules="formValidationRules.zipCode"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="8"
                          class="py-0"
                        >
                          <!-- Plaats -->
                          <v-text-field
                            label="plaats"
                            v-model="editProducerForm.city"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- Provincie -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="provincie"
                            v-model="editProducerForm.province"
                            :items="provincieOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card>

                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.lastEditedBy }}</i></small
                      >
                    </p>

                    <v-alert
                      v-if="formError != ''"
                      text
                      color="error"
                      >{{ formError }}</v-alert
                    >

                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="submit"
                      :loading="formLoading"
                      color="secondary"
                      class="text-lowercase mb-5 align-self-start"
                      >Opslaan
                      <v-icon
                        small
                        right
                        >mdi-content-save</v-icon
                      ></v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminProducersEdit',
  props: ['producerId'],
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editProducerForm: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        type: '',
        branchAssociationId: '',
      },
      lastEditedBy: '',
      provincieOptions: [],
      branchAssociations: [],
      ticketSystems: [],
      producerTypeOptions: [],
      formValidationRules: {},
      producername: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.getProducer();
    this.getproducerTypeOptions();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProvinces();
    this.getBranchAssociations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getProducer: function () {
      const vm = this;
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const producer = response.data.result;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + producer.id;
            this.producername = producer.name;
            this.editProducerForm.name = producer.name;
            this.editProducerForm.agreedToTerms = producer.agreedToTerms;
            this.editProducerForm.hasPublicMonitorAccess = producer.hasPublicMonitorAccess;
            this.editProducerForm.street = producer.street;
            this.editProducerForm.number = producer.number;
            this.editProducerForm.zipCode = producer.zipCode;
            this.editProducerForm.city = producer.city;
            this.editProducerForm.province = producer.province.id;
            this.editProducerForm.type = producer.type.id;

            if (producer.branchAssociation != null) {
              this.editProducerForm.branchAssociationId = producer.branchAssociation.id;
            }

            this.lastEditedBy = producer.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getproducerTypeOptions: function () {
      ApiService.get('/ProducerTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producerTypeOptions.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProducerForm;

        ApiService.put('/Producers/Update', {
          id: this.producerId,
          name: form.name,
          agreedToTerms: form.agreedToTerms,
          hasPublicMonitorAccess: form.hasPublicMonitorAccess,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          typeId: form.type,
          branchAssociationId: form.branchAssociationId != '' ? form.branchAssociationId : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editProducerForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
