<template>
  <v-menu
    offset-y
    :close-on-click="false"
    :close-on-content-click="false"
    v-model="filterOpen"
    min-width="40vw"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        class="font-weight-bold text-lowercase ma-1"
        color="primary"
        height="40"
        outlined
        depressed
        v-bind="activatorProps"
        >{{ selectedOptionText }}
        <v-icon
          small
          right
          >mdi-calendar-blank</v-icon
        ></v-btn
      >
    </template>
    <v-card>
      <v-card-text>
        <template v-if="selectOptions.length > 0">
          <h4 class="primary--text mb-2 font-weight-bold">snel keuzes</h4>
          <v-row>
            <v-col
              v-for="option in selectOptions"
              :key="option.value"
              class="pa-2"
              cols="12"
              md="6"
            >
              <v-btn
                v-if="option.dateFrom == filterDateFrom && option.dateTo == filterDateTo"
                @click="changeFilterDate(option)"
                class="font-weight-bold text-lowercase"
                color="primary"
                depressed
                block
                >{{ option.text }}</v-btn
              >
              <v-btn
                v-else
                @click="changeFilterDate(option)"
                class="font-weight-bold text-lowercase"
                color="primary"
                outlined
                block
                depressed
                >{{ option.text }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <hr />
            </v-col>
          </v-row>
          <h4 class="primary--text mb-2 font-weight-bold">of eigen datums</h4>
        </template>

        <v-form
          @submit.prevent
          v-model="formValid"
          class="d-flex flex-column"
          ref="form"
        >
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
            >
              <date-picker
                v-model="filterDateFromView"
                rules="dateRequired"
                label="datum vanaf"
              ></date-picker>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <date-picker
                v-model="filterDateToView"
                rules="dateRequired"
                label="datum tot"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-btn
                rounded
                large
                elevation="0"
                @click="customDates"
                color="secondary"
                class="text-lowercase align-self-start"
                >Filter</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import ApiService from '../services/api.service';
import { mapState, mapGetters, mapMutations } from 'vuex';
import functions from '../services/functions.service';
import DatePicker from '../components/DatePicker.vue';

export default {
  props: ['includeOptions', 'selectedDefault', 'customPeriod'],
  components: {
    DatePicker,
  },
  data() {
    return {
      filterOpen: false,
      formValid: false,
      dateNow: new Date(),
      selectedOptionText: '',
      selectOptions: [],
      options: {
        thisWeek: {
          value: 'thisWeek',
          text: 'deze week',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        thisSeason: {
          value: 'thisSeason',
          text: 'huidig seizoen',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        prevSeason: {
          value: 'prevSeason',
          text: 'vorig seizoen',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        nextSeason: {
          value: 'nextSeason',
          text: 'volgend seizoen',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        thisMonth: {
          value: 'thisMonth',
          text: 'komende maand',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        ninetyDays: {
          value: 'ninetyDays',
          text: '90 dagen',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
        custom: {
          value: 'custom',
          text: 'custom',
          dateFrom: '',
          dateTo: '',
          dateFromView: '',
          dateToView: '',
        },
      },
      filterDateFrom: null,
      filterDateTo: null,
      filterDateFromView: null,
      filterDateToView: null,
      seasons: [],
      formValidationRules: {},
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.setDates();
  },
  beforeDestroy() {
    if (!this.filterDateFrom) return;

    // set time to end of date
    var dateTo = new Date(this.filterDateTo);
    dateTo.setHours(23, 59, 59, 999);

    if (this.customPeriod === undefined) {
      this.setStoredFilterPeriod({
        dateFrom: this.filterDateFrom,
        dateFromView: this.filterDateFromView,
        dateTo: dateTo,
        dateToView: this.filterDateToView,
        text: this.selectedOptionText,
      });
    }
  },
  methods: {
    ...mapMutations('storage', ['setStoredFilterPeriod']),
    setDates: function () {
      // Seasons
      this.getSeasons();

      // This week
      this.options.thisWeek.dateFrom = new Date(this.dateNow);
      this.options.thisWeek.dateFromView = functions.getDateView(
        functions.jsDateToStringDate(this.options.thisWeek.dateFrom)
      );

      var thisWeekExtraDays = new Date(this.dateNow);
      this.options.thisWeek.dateTo = new Date(thisWeekExtraDays.setDate(thisWeekExtraDays.getDate() + 7));
      this.options.thisWeek.dateToView = functions.getDateView(
        functions.jsDateToStringDate(this.options.thisWeek.dateTo)
      );

      // This month
      this.options.thisMonth.dateFrom = new Date(this.dateNow);
      this.options.thisMonth.dateFromView = functions.getDateView(
        functions.jsDateToStringDate(this.options.thisMonth.dateFrom)
      );

      var thisMonthExtraDays = new Date(this.dateNow);
      this.options.thisMonth.dateTo = new Date(thisMonthExtraDays.setMonth(thisMonthExtraDays.getMonth() + 1));
      this.options.thisMonth.dateToView = functions.getDateView(
        functions.jsDateToStringDate(this.options.thisMonth.dateTo)
      );

      // 90 Days
      this.options.ninetyDays.dateFrom = new Date(this.dateNow);
      this.options.ninetyDays.dateFromView = functions.getDateView(
        functions.jsDateToStringDate(this.options.ninetyDays.dateFrom)
      );

      var ninetyExtraDays = new Date(this.dateNow);
      this.options.ninetyDays.dateTo = new Date(ninetyExtraDays.setDate(ninetyExtraDays.getDate() + 90));
      this.options.ninetyDays.dateToView = functions.getDateView(
        functions.jsDateToStringDate(this.options.ninetyDays.dateTo)
      );

      //TODO: Refactoring because of optional prop?
      // Custom
      if (this.customPeriod != null) {
        this.options.custom.dateFrom = this.customPeriod.from;
        this.options.custom.dateFromView = functions.getDateView(
          functions.jsDateToStringDate(this.options.custom.dateFrom)
        );

        this.options.custom.dateTo = this.customPeriod.to;
        this.options.custom.dateToView = functions.getDateView(
          functions.jsDateToStringDate(this.options.custom.dateTo)
        );
      }
    },
    getSeasons: function () {
      const vm = this;

      ApiService.get('/Seasons/GetAll')
        .then((response) => {
          vm.seasons = response.data.result;

          vm.seasons.forEach((element) => {
            var startDate = new Date(element.startDate);
            var endDate = new Date(element.endDate);

            if (vm.dateNow > startDate && vm.dateNow < endDate) {
              vm.options.thisSeason.dateFrom = new Date(startDate);
              vm.options.thisSeason.dateFromView = functions.getDateView(
                functions.jsDateToStringDate(vm.options.thisSeason.dateFrom)
              );
              vm.options.thisSeason.dateTo = new Date(endDate);
              vm.options.thisSeason.dateToView = functions.getDateView(
                functions.jsDateToStringDate(vm.options.thisSeason.dateTo)
              );

              this.getPrevSeason();
              this.getNextSeason();
            }
          });

          this.setSelect();

          if (this.storedFilterPeriod && !this.customPeriod) {
            this.changeFilterDate(this.storedFilterPeriod);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getPrevSeason: function () {
      const vm = this;

      var prevSeason = this.seasons.filter(function (season) {
        return new Date(season.startDate) < new Date(vm.options.thisSeason.dateFrom);
      });

      if (prevSeason.length > 0) {
        prevSeason = prevSeason.reduce((a, b) => {
          return new Date(a.startDate) > new Date(b.startDate) ? a : b;
        });
        this.options.prevSeason.dateFrom = new Date(prevSeason.startDate);
        this.options.prevSeason.dateFromView = functions.getDateView(
          functions.jsDateToStringDate(this.options.prevSeason.dateFrom)
        );
        this.options.prevSeason.dateTo = new Date(prevSeason.endDate);
        this.options.prevSeason.dateToView = functions.getDateView(
          functions.jsDateToStringDate(this.options.prevSeason.dateTo)
        );
      }
    },
    getNextSeason: function () {
      const vm = this;

      var nextSeason = this.seasons.filter(function (season) {
        return new Date(season.startDate) > new Date(vm.options.thisSeason.dateFrom);
      });

      if (nextSeason.length > 0) {
        nextSeason = nextSeason.reduce((a, b) => {
          return new Date(a.startDate) < new Date(b.startDate) ? a : b;
        });
        this.options.nextSeason.dateFrom = new Date(nextSeason.startDate);
        this.options.nextSeason.dateFromView = functions.getDateView(
          functions.jsDateToStringDate(this.options.nextSeason.dateFrom)
        );
        this.options.nextSeason.dateTo = new Date(nextSeason.endDate);
        this.options.nextSeason.dateToView = functions.getDateView(
          functions.jsDateToStringDate(this.options.nextSeason.dateTo)
        );
      }
    },
    setSelect: function () {
      this.includeOptions.forEach((element) => {
        var option = this.options[element];
        if (option.dateFrom != '' && option.dateTo != '') {
          this.selectOptions.push(option);
        }
      });
      this.filterDateFrom = this.options[this.selectedDefault].dateFrom;
      this.filterDateFromView = this.options[this.selectedDefault].dateFromView;
      this.filterDateTo = this.options[this.selectedDefault].dateTo;
      this.filterDateToView = this.options[this.selectedDefault].dateToView;
      this.selectedOptionText = this.options[this.selectedDefault].text;

      var dateFrom = new Date(this.filterDateFrom);

      // set time to end of date
      var dateTo = new Date(this.filterDateTo);
      dateTo.setHours(23, 59, 59, 999);

      this.$emit('changePeriod', {
        from: dateFrom,
        to: dateTo,
      });
    },
    changeFilterDate: function (option) {
      this.filterDateFrom = option.dateFrom;
      this.filterDateFromView = option.dateFromView;
      this.filterDateTo = option.dateTo;
      this.filterDateToView = option.dateToView;
      this.selectedOptionText = option.text;

      var dateFrom = new Date(this.filterDateFrom);

      // set time to end of date
      var dateTo = new Date(this.filterDateTo);
      dateTo.setHours(23, 59, 59, 999);

      this.$emit('changePeriod', { from: dateFrom, to: dateTo });
      this.filterOpen = false;
    },
    customDates: function () {
      if (this.formValid) {
        var option = {
          text: 'custom',
          dateFrom: functions.getDateDatabase(this.filterDateFromView),
          dateTo: functions.getDateDatabase(this.filterDateToView),
          dateFromView: this.filterDateFromView,
          dateToView: this.filterDateToView,
        };
        this.changeFilterDate(option);
      } else {
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['storedFilterPeriod']),

    hasCustomPeriod() {
      return this.customPeriod || null;
    },
  },
};
</script>

<style>
/* scoped */
.v-select.v-select--is-menu-active.icon-not-rotate .v-input__icon--append .v-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
</style>
