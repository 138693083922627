<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          v-if="rights.edit && user.organisationTypeId != 2"
          flex
          align="center"
          justify="space-between"
          class="pa-2 mb-4"
        >
          <v-col
            cols="12"
            class="py-3 add-row d-flex justify-sm-space-between align-center"
          >
            <span class="d-none d-sm-flex">een nieuwe deal aanmaken?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'ContractsAdd' }"
            >
              nieuwe deal aanmaken
              <v-icon
                small
                right
                >mdi-file-document</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              v-if="user.organisationTypeId == 1"
              bg-color="#fff"
              help-id="1111"
            ></help>
            <help
              v-else-if="user.organisationTypeId == 2"
              bg-color="#fff"
              help-id="1112"
            ></help>
            <help
              v-else-if="user.organisationTypeId == 3"
              bg-color="#fff"
              help-id="1113"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>

              <v-text-field
                v-if="user.organisationTypeId != 2"
                v-model="tableContractsFilter.theater"
                label="zoek op afnemer"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-if="user.organisationTypeId != 3"
                v-model="tableContractsFilter.producer"
                label="aanbieder zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableContractsFilter.production"
                label="productie zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>

              <v-autocomplete
                :items="statuses"
                v-model="tableContractsFilter.status"
                item-text="text"
                label="status selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                :items="types"
                v-model="tableContractsFilter.type"
                label="type afspraak selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog
                v-model="tableContractsFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <filter-period
                      :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason"
                      @changePeriod="filterPeriod = $event"
                    ></filter-period>

                    <v-text-field
                      v-if="user.organisationTypeId != 2"
                      v-model="tableContractsFilter.theater"
                      label="zoek op afnemer"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-text-field
                      v-if="user.organisationTypeId != 3"
                      v-model="tableContractsFilter.producer"
                      label="aanbieder zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableContractsFilter.production"
                      label="productie zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>

                    <v-autocomplete
                      :items="statuses"
                      item-text="text"
                      label="status selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                    <v-autocomplete
                      :items="types"
                      label="type afspraak selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableContractsFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterContracts()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableContractsHeadersFiltered"
          :items="tableContractsFiltered"
          :mobile-breakpoint="0"
          :loading="tableContractsLoading"
          :page.sync="tableContractsPage"
          :items-per-page="tableContractsItemsPerPage"
          hide-default-footer
          @update:current-items="tableContractsPageCount = Math.ceil(tableContractsFiltered.length / tableContractsItemsPerPage)"
          @update:items-per-page="tableContractsPageCount = Math.ceil(tableContractsFiltered.length / tableContractsItemsPerPage)"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableContractsFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.modified`]="{ item }">{{ item.modifiedView }}</template>
          <template v-slot:[`item.firstPerformance`]="{ item }">{{ item.firstPerformanceView }}</template>

          <template v-slot:[`item.totalExVat`]="{ item }">
            <v-chip
              v-if="item.totalExVat > 0"
              color="success"
              small
              label
              ><strong>{{ getEuroSign(item.totalExVat) }}</strong></v-chip
            ><v-chip
              v-else-if="item.totalExVat < 0"
              color="error"
              small
              label
              ><strong>{{ getEuroSign(item.totalExVat) }}</strong></v-chip
            >
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <v-chip
              v-if="item.state == 'goedgekeurd'"
              color="success"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
            <v-chip
              v-else-if="item.state == 'voorstel'"
              color="accent"
              class="primary--text"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
            <v-chip
              v-else-if="item.state == 'concept'"
              class="primary--text"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
            <v-chip
              v-else-if="item.state == 'aangepast voorstel'"
              color="warning"
              class="primary--text"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
            <v-chip
              v-else-if="item.state == 'herzien'"
              color="warning"
              class="primary--text"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
            <v-chip
              v-else-if="item.state == 'afgekeurd'"
              color="error"
              small
              label
              ><strong>{{ item.state }}</strong></v-chip
            >
          </template>

          <template v-slot:[`item.fixedAmount`]="{ item }">{{ item.fixedAmountView }}</template>
          <template v-slot:[`item.percentage`]="{ item }">{{ item.percentageView }}</template>

          <template v-slot:[`item.action`]="{ item }">
            <!-- Edit -->
            <v-btn
              v-if="
                rights.edit && (item.state == 'concept' || item.state == 'afgekeurd') && user.organisationTypeId != 2
              "
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{
                name: 'ContractsEdit',
                params: { type: item.type.toLowerCase(), contractId: item.id },
              }"
              ><v-icon color="primary">mdi-square-edit-outline</v-icon></v-btn
            >
            <!-- Delete -->
            <v-btn
              v-if="rights.edit && (item.state == 'concept' || item.state == 'afgekeurd') && item.canDelete"
              fab
              x-small
              depressed
              color="error"
              class="ml-1"
              @click.stop="(deleteItem = item), (deleteItemDialog = true);"
              ><v-icon color="#fff">mdi-delete</v-icon></v-btn
            >
            <!-- Watch -->
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'ContractsDetail',
                params: { type: item.type.toLowerCase(), contractId: item.id },
              }"
              ><v-icon color="primary">mdi-eye</v-icon></v-btn
            >
          </template>

        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableContractsFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableContractsFiltered.length > 25"
              @click="tableContractsItemsPerPage = 25"
              :color="tableContractsItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableContractsFiltered.length > 25"
              @click="tableContractsItemsPerPage = 50"
              :color="tableContractsItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableContractsFiltered.length > 50"
              @click="tableContractsItemsPerPage = 100"
              :color="tableContractsItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableContractsPageCount > 1"
              v-model="tableContractsPage"
              :length="tableContractsPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableContractsFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-financiele-afspraken-export'"
                :sheetname="'financiële afspraken'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.code }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.code }} wilt verwijderen? Het is daarna niet meer terug te vinden.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemContract()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'Contracts',
  components: {
    Help,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'financiële afspraken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      contracts: [],
      statuses: [],
      types: [],
      tableContractsFiltered: [],
      tableContractsLoading: true,
      tableContractsHeaders: [
        { title: 'id', key: 'id' },
        { title: 'kenmerk', key: 'code' },
        { title: 'productie', key: 'production' },
        { title: 'producent', key: 'producer' },
        { title: 'afnemer', key: 'theater', showFor: [1, 3] },
        { title: 'impresariaat', value: 'agency', showFor: [2] },
        { title: 'vast bedrag', value: 'fixedAmount' },
        { title: 'percentage', value: 'percentage' },
        { title: 'datum 1e voorstelling', value: 'firstPerformance' },
        { title: 'mijn aandeel', value: 'totalExVat' },
        { title: 'laatste wijziging', value: 'modified' },
        { title: 'status', value: 'state' },
        { title: 'type afspraak', value: 'type' },
        { title: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableContractsFilter: {
        theater: '',
        producer: '',
        production: '',
        status: '',
        type: '',
      },
      tableContractsPage: 1,
      tableContractsPageCount: 0,
      tableContractsItemsPerPage: 25,
      tableContractsFilterDialog: false,
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      downloadExportColumns: [
        { label: 'kenmerk', field: 'code' },
        { label: 'productie', field: 'production' },
        { label: 'producent', field: 'producer' },
        { label: 'afnemer', field: 'theater' },
        { label: 'vast bedrag', field: 'fixedAmount' },
        { label: 'percentage', field: 'percentage' },
        { label: 'datum 1e voorstelling', field: 'firstPerformance' },
        { label: 'mijn aandeel', field: 'totalExVat' },
        { label: 'laatste wijziging', field: 'modified' },
        { label: 'status', field: 'state' },
        { label: 'type afspraak', field: 'type' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('Contracts');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableContractsFilter,
    });
    this.tableContractsFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'ContractsDetail',
        params: { type: row.type.toLowerCase(), contractId: row.id },
      });
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getContracts: function () {
      this.contracts = [];
      this.tableContractsFiltered = [];
      this.tableContractsLoading = true;

      ApiService.get('/Contracts/GetAll', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.contracts = response.data.result;

          this.contracts = this.contracts.map(contract => {
            return {
              ...contract,
              firstPerformanceView: functions.getDateTimeView(contract.firstPerformance),
              modifiedView: functions.getDateTimeView(contract.modified),
              fixedAmountView: functions.getEuroSignView(contract.fixedAmount).replace('.', ''),
              percentageView: contract.percentage != null ? contract.percentage + '%' : '',
              state: functions.getStatusNameNL(contract.state),
            };
          });

          this.types = this.contracts
            .map(contract => contract.type)
            .filter((type, index, self) => self.indexOf(type) === index)
            .map(type => ({
              value: type,
              title: type,
            }));

          this.statuses = this.contracts
            .map(contract => contract.state)
            .filter((state, index, self) => self.indexOf(state) === index)
            .map(state => ({
              value: state,
              title: state,
            }));

          this.tableContractsFiltered = this.contracts;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableContractsPage,
          });
          this.tableContractsPage = this.getFilter(this.$options.name + '-paging');

          this.filterContracts();
          this.tableContractsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    filterContracts: function () {
      const vm = this;

      this.tableContractsFiltered = this.contracts.filter(function (contract) {
        return (
          cleanWords(contract.theater).indexOf(cleanWords(vm.tableContractsFilter.theater)) !== -1 &&
          (cleanWords(contract.producer).indexOf(cleanWords(vm.tableContractsFilter.producer)) !== -1 ||
            cleanWords(contract.agency).indexOf(cleanWords(vm.tableContractsFilter.producer)) !== -1) &&
          cleanWords(contract.production).indexOf(cleanWords(vm.tableContractsFilter.production)) !== -1 &&
          cleanWords(contract.state).indexOf(cleanWords(vm.tableContractsFilter.status)) !== -1 &&
          cleanWords(contract.type).indexOf(cleanWords(vm.tableContractsFilter.type)) !== -1
        );
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterContracts: function () {
      this.tableContractsFilter.theater = '';
      this.tableContractsFilter.producer = '';
      this.tableContractsFilter.production = '';
      this.tableContractsFilter.status = '';
      this.tableContractsFilter.type = '';
      this.tableContractsFilterDialog = false;
    },
    deleteItemContract: function () {
      ApiService.delete('/Contracts/Delete', {
        contractId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.contracts.map((item) => item.id).indexOf(this.deleteItem.id);
            this.contracts.splice(i, 1);
            this.filterContracts();

            this.snackbarText = this.deleteItem.code + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;

            this.getContracts();
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),
    tableContractsHeadersFiltered() {
      return this.tableContractsHeaders.filter((h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId));
    },
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableContractsFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableContractsPage,
    });
  },
  watch: {
    tableContractsFilter: {
      handler: 'filterContracts',
      deep: true,
    },
    filterPeriod: function () {
      this.getContracts();
    },
  },
};
</script>
