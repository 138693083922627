<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1121"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h1 class="primary--text">{{ this.producer.name }}</h1>
                  <h2 class="primary--text mb-5">{{ this.production.title }}</h2>
                  <h4 class="primary--text mb-2 font-weight-regular">impresariaat koppeling wijzigen</h4>

                  <v-card
                    class="pa-6 mb-5"
                    flat
                  >
                    <v-row class="mt-0">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="impresariaat koppeling"
                          v-model="editProductionLinkForm.agencyId"
                          :items="agencyOptions"
                          outlined
                          dense
                          clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          placeholder="DD-MM-JJJJ"
                          v-model="editProductionLinkForm.date"
                          :rules="formValidationRules.dateMaxToday"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="checkChangeProductionAgencyLink"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog agency link starts here -->
    <v-dialog
      v-model="changeProductionAgencyLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-alert
          v-if="changeProductionAgencyLinkDialogInfo.warning"
          type="warning"
          color="#fb8c00"
          >Let op!</v-alert
        >
        <v-card-title class="headline">{{ changeProductionAgencyLinkDialogInfo.title }}</v-card-title>
        <v-card-text>
          {{ changeProductionAgencyLinkDialogInfo.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              changeProductionAgencyLinkDialog = false;
              formLoading = false;
            "
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="changeProductionAgencyLink()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminProducersProductionsAgencyLinkEdit',
  props: ['producerId', 'productionNumber'],
  components: {
    Help,
  },
  data() {
    return {
      producer: {},
      production: {},
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editProductionLinkForm: {
        agencyId: null,
        date: '',
      },
      formValidationRules: {},
      agencyOptions: [],
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',

      changeProductionAgencyLinkDialog: false,
      changeProductionAgencyLinkDialogInfo: {
        title: '',
        text: '',
        warning: false,
      },
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getProducer();
    this.getAgencies();
    this.getProduction();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getProducer: function () {
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const producer = response.data.result;
            this.producer = producer;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + this.producerId + '/';
            this.breadcrumbs[3].href = '/admin/producenten/' + this.producerId + '/producties';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencies: function () {
      ApiService.get('/AgencyProducerLinks/GetPerProducer', {
        producerId: this.producerId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.agencyOptions.push({ value: element.agencyId, text: element.agencyName });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getProduction: function () {
      const vm = this;

      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[4].text = production.title;
            this.production = production;
            this.editProductionLinkForm.agencyId = production.agency ? production.agency.id : null;
            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    checkChangeProductionAgencyLink() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProductionLinkForm;

        ApiService.get('/Productions/CanChangeProductionAgency', {
          productionNumber: this.productionNumber,
          startDate: form.date != '' ? functions.getDateDatabase(form.date) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              this.changeProductionAgencyLinkDialogInfo.title = response.data.result.title;
              this.changeProductionAgencyLinkDialogInfo.text = response.data.result.text;
              this.changeProductionAgencyLinkDialogInfo.warning = !response.data.result.canChange;
              this.changeProductionAgencyLinkDialog = true;
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    changeProductionAgencyLink() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProductionLinkForm;

        ApiService.put('/Productions/ChangeProductionAgency', {
          productionNumber: this.productionNumber,
          agencyId: form.agencyId,
          startDate: form.date != '' ? functions.getDateDatabase(form.date) : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              this.snackbarText = 'koppeling is aangepast';
              this.changeProductionAgencyLinkDialog = false;
              this.snackbarColor = 'success';
              this.snackbar = true;
              this.formLoading = false;
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editProductionLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
