<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1084"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="user.roleId != null && (user.roleId == 18 || user.roleId == 19 || user.roleId == 20)"
          flex
          justify="center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <h1 class="primary--text mb-5">
              Je beheerder moet je rechten nog toekennen.<br />Neem contact op met je beheerder.
            </h1>
          </v-col>
        </v-row>
        <!-- Dashboard items Impresariaten -->
        <v-row
          flex
          v-if="this.user.organisationTypeId == 1"
        >
          <v-col
            cols="12"
            sm="12"
            md="4"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <production-performance-count
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="1079"
              :filterPeriod="filterPeriod"
            ></production-performance-count>

            <capacity
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="67"
              :filterPeriod="filterPeriod"
            ></capacity>

            <divide-performances-province
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              :help-id="83"
              :filterPeriod="filterPeriod"
            ></divide-performances-province>

            <performances-this-week
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="80"
            ></performances-this-week>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="8"
            class="pa-0 ma-0"
          >
            <open-contracts
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              help-id="85"
            ></open-contracts>

            <area-occupation
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="79"
              :filterPeriod="filterPeriod"
            ></area-occupation>
          </v-col>
        </v-row>

        <!-- Dashboard items Producers -->
        <v-row
          flex
          v-else-if="this.user.organisationTypeId == 3"
        >
          <v-col
            cols="12"
            sm="12"
            md="4"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <production-performance-count
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="1079"
              :filterPeriod="filterPeriod"
            ></production-performance-count>

            <capacity
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="67"
              :filterPeriod="filterPeriod"
            ></capacity>

            <divide-performances-province
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              :help-id="83"
              :filterPeriod="filterPeriod"
            ></divide-performances-province>

            <performances-this-week
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="80"
            ></performances-this-week>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="8"
            class="pa-0 ma-0"
          >
            <open-contracts
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              help-id="85"
            ></open-contracts>

            <area-occupation
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="79"
              :filterPeriod="filterPeriod"
            ></area-occupation>
          </v-col>
        </v-row>

        <!-- Dashboard items Theaters -->
        <v-row
          flex
          v-else-if="this.user.organisationTypeId == 2"
        >
          <v-col
            cols="12"
            sm="12"
            md="4"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <production-performance-count
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="68"
              :filterPeriod="filterPeriod"
            ></production-performance-count>

            <capacity
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="67"
              :filterPeriod="filterPeriod"
            ></capacity>

            <performances-this-week
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              :help-id="72"
            ></performances-this-week>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="8"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <open-contracts
              :cols-desktop="12"
              :cols-tablet="12"
              :cols-mobile="12"
              help-id="74"
            ></open-contracts>

            <area-occupation
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="87"
              :filterPeriod="filterPeriod"
            ></area-occupation>
          </v-col>
        </v-row>
        <!-- START MAP -->
        <!-- <map-chart></map-chart> -->
        <!-- END MAP -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import Help from '../../components/Help';
import FilterPeriod from '../../components/FilterPeriod.vue';

// Dashboard components
import ProductionPerformanceCount from '../../components/widgets/ProductionPerformanceCount';
import PerformancesThisWeek from '../../components/widgets/PerformancesThisWeek';
import Capacity from '../../components/widgets/Capacity';
import OpenContracts from '../../components/widgets/OpenContracts.vue';
import AreaOccupation from '../../components/widgets/AreaOccupation.vue';
import DividePerformancesProvince from '../../components/widgets/DividePerformancesProvince';
// import MapChart from '../../components/widgets/MapChart.vue';

export default {
  name: 'Dashboard',
  components: {
    Help,
    FilterPeriod,
    ProductionPerformanceCount,
    PerformancesThisWeek,
    Capacity,
    OpenContracts,
    AreaOccupation,
    DividePerformancesProvince,
    // MapChart
  },
  data() {
    return {
      filterPeriod: {
        from: '',
        to: '',
      },
      breadcrumbs: [
        {
          text: 'dashboard',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['user', 'navigationStatus']),
  },
  watch: {},
};
</script>

<style>
#freshwidget-button {
  display: none !important;
}
</style>