<template>
  <span>
    <v-btn
      v-if="user.isSuperUser"
      :color="bgColor"
      class="ml-2"
      width="24"
      height="24"
      rounded="circle"
      density="default"
      size="x-small"
      variant="flat"
      @click="dialogEdit = true"
    >
      <v-icon
        color="primary"
        x-small
        >mdi-cog</v-icon
      >
    </v-btn>
    <v-btn
      :color="bgColor"
      class="ml-2"
      width="24"
      height="24"
      rounded="circle"
      density="default"
      size="x-small"
      variant="flat"
      @click="dialogHelp = true"
    >
      <v-icon
        color="primary"
        x-small
        >mdi-help</v-icon
      >
    </v-btn>

    <v-dialog
      v-model="dialogHelp"
      max-width="700px"
    >
      <v-card>
        <v-btn
          color="#fff"
          class="mt-8"
          top
          right
          absolute
          fab
          x-small
          depressed
          light
          @click="dialogHelp = false"
        >
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="primary--text">{{ originalTitle }}</v-card-title>
        <v-card-text class="primary--text caption pre-wrap"><span v-html="urlInText(originalText)"></span></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="user.isSuperUser"
      v-model="dialogEdit"
      max-width="700px"
    >
      <v-card>
        <v-btn
          color="#fff"
          class="mt-8"
          top
          right
          absolute
          fab
          x-small
          depressed
          light
          @click="
            helpText = originalText;
            dialogEdit = false;
          "
        >
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="primary--text">Bewerken helpfunctie</v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent
            ref="form"
            v-model="formValid"
            class="pt-5"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  class="primary--text"
                  label="titel"
                  v-model="editTitle"
                  :rules="formValidationRules.required"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-textarea
                  class="primary--text"
                  label="tekst"
                  v-model="editText"
                  :rules="formValidationRules.required"
                  required
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-btn
                  rounded
                  large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 mr-3 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
                <v-btn
                  rounded
                  outlined
                  large
                  elevation="0"
                  @click="
                    editText = originalText;
                    editTitle = originalTitle;
                    dialogEdit = false;
                  "
                  color="error"
                  class="text-lowercase mb-5 align-self-start"
                  >Annuleren
                  <v-icon
                    small
                    right
                    >mdi-close</v-icon
                  ></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import ApiService from '../services/api.service';
import { mapState } from 'vuex';
import functions from '../services/functions.service';

export default {
  props: ['helpId', 'bgColor'],
  data() {
    return {
      originalText: '',
      originalTitle: '',
      editText: '',
      editTitle: '',
      dialogHelp: false,
      dialogEdit: false,
      formLoading: false,
      formValid: false,
      formValidationRules: {},
    };
  },
  mounted() {
    this.getHelp();
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    urlInText(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    },
    getHelp: function () {
      ApiService.get('/HelpFunctions/Get', {
        id: this.helpId,
      })
        .then((response) => {
          var help = response.data.result;

          this.originalTitle = help.title;
          this.editTitle = help.title;

          this.originalText = help.text;
          this.editText = help.text;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        ApiService.put('/HelpFunctions/Update', {
          id: this.helpId,
          title: this.editTitle,
          text: this.editText,
        })
          .then((response) => {
            if (response.data.success) {
              this.originalText = this.editText;
              this.originalTitle = this.editTitle;
              this.formLoading = false;
              this.dialogEdit = false;
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>