<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1132"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="primary--text mb-5">mijn account</h1>
                  <v-row>
                    <!-- Form mail settings -->
                    <v-form
                      @submit.prevent
                      v-model="mailFormValid"
                      class="d-flex flex-column col col-12"
                      ref="mailForm"
                    >
                      <h4 class="primary--text mb-5">mailinstellingen</h4>
                      <v-card
                        class="pa-6 pt-3 mb-5"
                        flat
                        :loading="dataLoading"
                      >
                        <!-- FA -->
                        <v-row
                          class="mt-0"
                          v-if="editMailForm.receiveContractMails != null"
                        >
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-radio-group
                              v-model="editMailForm.receiveContractMails"
                              label="Wil je wekelijkse mails ontvangen over openstaande voorstellen voor financiële afspraken?"
                              row
                              class="input-radio-row-label"
                            >
                              <v-radio
                                label="ja"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="nee"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <!-- Performances -->
                        <v-row
                          class="mt-0"
                          v-if="editMailForm.receivePerformanceMails != null"
                        >
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-radio-group
                              v-model="editMailForm.receivePerformanceMails"
                              label="Wil je wekelijks herinneringsmails ontvangen met voorstellingen waarvoor nog geen kaartstanden zijn geregistreerd in DIP?"
                              row
                              class="input-radio-row-label"
                            >
                              <v-radio
                                label="ja"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="nee"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <!-- Productions -->
                        <v-row
                          class="mt-0"
                          v-if="editMailForm.receiveProductionMails != null && user.roleId == 5"
                        >
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-radio-group
                              v-model="editMailForm.receiveProductionMails"
                              label="Wil je updates ontvangen van producties waar een document aan gekoppeld wordt?"
                              row
                              class="input-radio-row-label"
                            >
                              <v-radio
                                label="ja"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="nee"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <!-- Subvention -->
                        <v-row
                          class="mt-0"
                          v-if="editMailForm.receiveSubventionMails != null"
                        >
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-radio-group
                              v-model="editMailForm.receiveSubventionMails"
                              label="Wil jij de contactpersoon zijn voor de FPK-verantwoording?"
                              row
                              class="input-radio-row-label"
                            >
                              <v-radio
                                label="ja"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="nee"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row
                          class="mt-0"
                          v-if="mailFormItemCount <= 0"
                        >
                          <v-col
                            cols="12"
                            class="py-0 pt-4"
                          >
                            <p>Er zijn nog geen mailinstellingen voor je</p>
                          </v-col>
                        </v-row>
                      </v-card>

                      <h4 class="primary--text mb-5">wachtwoord wijzigen</h4>
                      <v-card
                        class="pa-6 pt-3 mb-5"
                        flat
                        :loading="dataLoading"
                      >
                        <!-- Change password -->
                        <v-row class="mt-0">
                          <v-col
                            cols="12"
                            class="pb-0"
                          >
                            <v-text-field
                              label="Huidige Wachtwoord"
                              v-model="editMailForm.oldPassword"
                              password
                              type="password"
                              outlined
                              autocomplete="off"
                              append-icon="mdi-lock"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            class="py-0"
                            v-if="editMailForm.oldPassword.length > 0"
                          >
                            <v-text-field
                              label="Wachtwoord"
                              validate-on-blur
                              v-model="editMailForm.newPassword"
                              password
                              :rules="newPasswordRules"
                              type="password"
                              outlined
                              autocomplete="off"
                              append-icon="mdi-lock"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            class="pt-0"
                            v-if="editMailForm.oldPassword.length > 0"
                          >
                            <v-text-field
                              label="Wachtwoord herhaling"
                              validate-on-blur
                              v-model="editMailForm.newPasswordConfirm"
                              password
                              :rules="newPasswordConfirmRules"
                              type="password"
                              outlined
                              autocomplete="off"
                              append-icon="mdi-lock"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>

                      <v-alert
                        v-if="mailFormError != ''"
                        text
                        color="error"
                        >{{ mailFormError }}</v-alert
                      >

                      <v-alert
                        v-if="mailFormSuccess != ''"
                        text
                        color="success"
                      >
                        {{ mailFormSuccess }}
                      </v-alert>

                      <v-btn
                        v-if="mailFormItemCount > 0"
                        rounded
                        large
                        elevation="0"
                        @click="submitMailSettings"
                        :loading="mailFormLoading"
                        color="secondary"
                        class="text-lowercase mb-5 align-self-start"
                        >Opslaan
                        <v-icon
                          small
                          right
                          >mdi-content-save</v-icon
                        ></v-btn
                      >
                    </v-form>
                  </v-row>
                  <v-row>
                      <h4 class="primary--text mb-5">instellingen met twee factoren</h4>
                      <div>
                      <!-- Form 2FA settings -->
                        <v-card class="pa-6 pt-3 mb-5" flat :loading="dataLoading">
                          <v-container v-if="user.twoFactorEnabled">
                            <v-row class="mt-0">
                              <v-col cols="12"><h4>Tweefactorauthenticatie (2FA) instellen.</h4></v-col>
                              <v-col cols="12">
                                  <p>Tweefactorauthenticatie is ingeschakeld voor dit account.</p>
                                </v-col>
                                <v-btn
                                  rounded
                                  elevation="0"
                                  @click="disable2FASettings"
                                  color="error"
                                  class="text-lowercase mb-5 align-self-start"
                                  >2FA uitschakelen
                                  <v-icon small right>mdi mdi-trash-can</v-icon
                              ></v-btn>
                            </v-row>
                          </v-container>

                          <v-form v-else @submit.prevent class="d-flex flex-column">
                            <v-container>
                              <v-row class="mt-0">
                              <v-col cols="12"><h4>Tweefactorauthenticatie (2FA) instellen.</h4></v-col>
                              <v-col cols="12">
                                <h6>Stap 1</h6>
                                <h5>Download app</h5>
                                <p>Download een mobiele authenticator-app zoals Google Authenticator</p>
                              </v-col>
                              <v-col cols="8">
                                <h6>Stap 2</h6>
                                <h5>Scan QR-code</h5>
                                <p>Scan de QR-code met de mobiele authenticator-app om een verificatiecode te genereren. Als scannen niet lukt, kun je ook de volgende code handmatig in de app invoeren:</p>
                                <p>Code: <strong>{{ twoFactorSetupKey }}</strong></p>
                              </v-col>
                              <v-col cols="4">
                                <v-img :src="qrCodeValue" max-height="150" max-width="150" alt="QR Code" />
                              </v-col>
                              <v-col cols="12">
                                <h6>Stap 3</h6>
                                <h5>Voer verificatiecode in</h5>
                                <v-text-field
                                  label="Authenticatiecode"
                                  v-model="authCode"
                                  hide-details
                                  dense
                                  outlined
                                  background-color="#fff"
                                  class="ma-1 input-sm"
                                ></v-text-field>
                              </v-col>
                              <v-btn
                              rounded
                              large
                              elevation="0"
                              @click="submit2FASettings"
                              color="secondary"
                              class="text-lowercase mb-5 align-self-start"
                              >Verifieer en activeer
                              <v-icon small right>mdi mdi-shield-check</v-icon
                              ></v-btn>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card>
                        <v-alert v-if="twoFactorFormError != ''" text color="error">{{ twoFactorFormError }}</v-alert>
                      </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '@/services/api.service';
import functions from '@/services/functions.service';
import { TokenService } from '@/services/storage.service';
import router from '@/router/router.js';
import { mapState, mapMutations } from 'vuex';
import Help from '@/components/Help.vue';

export default {
  name: 'Account',
  components: {
    Help,
  },
  data() {
    return {
      dataLoading: true,
      isEditing: false,
      mailFormValid: false,
      mailFormError: '',
      mailFormSuccess: '',
      mailFormLoading: false,
      editMailForm: {
        receiveContractMails: null,
        receiveSubventionMails: null,
        receivePerformanceMails: null,
        receiveProductionMails: null,
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      twoFactorFormError: '',
      qrCodeValue: '',
      twoFactorSetupKey: '',
      authCode: '',
      customerKey: '',
      newPasswordRules: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => !v || v.length >= 8 || 'meer dan 8 karakters zijn vereist',
      ],
      newPasswordConfirmRules: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => v === this.editMailForm.newPassword || 'de wachtwoorden komen niet overeen',
        (v) => !v || v.length >= 8 || 'meer dan 8 karakters zijn vereist',
      ],
      mailFormItemCount: 0,
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'mijn account',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    const vm = this;

    let userInterval = setInterval(function () {
      if (vm.user.fullName) {
        clearInterval(userInterval);
        vm.breadcrumbs[1].text = vm.user.fullName;
      }
    }, 10);

    this.formValidationRules = functions.getFormValidationRules();
    this.getMailSettings();
    
    if (!vm.user.twoFactorEnabled) {
      this.get2FASettings();
    } else {
      this.dataLoading = false;
    }
  },
  methods: {
    ...mapMutations('auth', ['setUser']),
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getMailSettings: function () {
      const vm = this;
      vm.mailFormItemCount = 0;

      ApiService.get('/users/GetSettings')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const result = response.data.result;
            this.editMailForm.receiveContractMails = result.receiveContractMails;
            this.editMailForm.receiveSubventionMails = result.receiveSubventionMails;
            this.editMailForm.receivePerformanceMails = result.receivePerformanceMails;
            this.editMailForm.receiveProductionMails = result.receiveProductionMails;

            for (var i in this.editMailForm) {
              if (this.editMailForm[i] != null) {
                this.mailFormItemCount++;
              }
            }
            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    get2FASettings() {
      ApiService.get('/users/GetTwoFactorSettings')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.customerKey = response.data.result.customerSecretKey;
            this.qrCodeValue = response.data.result.qrCodeImageData;
            this.twoFactorSetupKey = response.data.result.twoFactorSetupKey;
            this.dataLoading = false;
          } else {
            this.errored = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit2FASettings() {
      if(this.authCode.length < 6) {
        this.twoFactorFormError = 'Voer een geldige authenticatiecode in.';
        return;
      }
      ApiService.post('/users/CreateTwoFactorSettings', {
        ValidationKey: this.authCode,
        CustomerKey: this.customerKey,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.twoFactorFormError = '';
            this.user.twoFactorEnabled = true;
            this.setUser(this.user);

            router.go();
          } else {
            this.twoFactorFormError = 'Er is iets misgegaan. Probeer het opnieuw.';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.twoFactorFormError = 'Er is iets misgegaan. Probeer het opnieuw.';
        });
    },
    disable2FASettings() {
      ApiService.delete('/users/DeleteTwoFactorSettings')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.twoFactorFormError = '';
            this.user.twoFactorEnabled = false;
            this.setUser(this.user);

            router.go();
          } else {
            this.twoFactorFormError = 'Er is iets misgegaan. Probeer het opnieuw.';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.twoFactorFormError = 'Er is iets misgegaan. Probeer het opnieuw.';
        });
    },
    submitMailSettings() {
      this.mailFormError = '';
      this.mailFormSuccess = '';
      this.mailFormLoading = true;
      if (this.mailFormValid) {
        const form = this.editMailForm;

        ApiService.put('/users/UpdateSettings', {
          receiveContractMails: form.receiveContractMails,
          receiveSubventionMails: form.receiveSubventionMails,
          receivePerformanceMails: form.receivePerformanceMails,
          receiveProductionMails: form.receiveProductionMails,
          oldPassword: form.oldPassword != '' ? form.oldPassword : null,
          newPassword: form.newPasswordConfirm != '' ? form.newPasswordConfirm : null,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              this.mailFormError = '';
              this.mailFormSuccess = 'Uw instellingen zijn opgeslagen.';
              this.mailFormLoading = false;

              setTimeout(() => {
                router.back();
              }, 1000);
            } else {
              this.mailFormError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';

              form.oldPassword = '';
              form.newPassword = '';
              form.newPasswordConfirm = '';
              this.mailFormLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.mailFormError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.mailFormLoading = false;
          });
      } else {
        this.mailFormLoading = false;
        this.$refs.mailForm.validate();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    editMailForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>
