<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="dividePerformancesProvinceLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">verdeling voorstellingen</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-list
                flat
                v-if="provinces.length"
              >
                <v-list-item
                  v-for="(item, i) in provinces"
                  :key="i"
                  dense
                >
                    <v-list-item-title
                      v-text="item.province"
                      class="primary--text"
                    ></v-list-item-title>

                  <template #prepend>
                    <p class="primary--text font-weight-medium mb-0">
                      {{ formatThousandsWithDot(item.performanceCount) }}
                    </p>
                  </template>
                </v-list-item>
              </v-list>

              <v-list v-else>
                <v-list-item>
                    <div class="primary--text v-list-item-subtitle"
                    >er zijn geen voorstellingen gevonden</div>
                </v-list-item>
              </v-list>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../Help.vue';
import functions from '../../services/functions.service';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'filterPeriod'],
  components: {
    Help,
  },
  data() {
    return {
      chart: {
        loaded: false,
      },
      provinces: [],
      dividePerformancesProvinceLoading: true,
    };
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getData() {
      this.chart.loaded = false;

      ApiService.get('/Dashboard/GetPerformancesPerProvince', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;
        this.provinces = data;

        // Load chart
        this.chart.loaded = true;
        this.dividePerformancesProvinceLoading = false;
      });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: function () {
      this.getData();
    },
  },
};
</script>

<style scoped></style>