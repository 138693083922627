<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="py-2">
            <v-row
              flex
              align="center"
              class="pa-0"
            >
              <v-breadcrumbs
                :items="breadcrumbs"
                class="pa-0 pl-4"
              >
                <template v-slot:divider>
                  <v-icon class="primary--text">mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                    :to="{ path: item.href }"
                    :exact="true"
                    :disabled="item.disabled"
                  >
                    <span
                      class="primary--text"
                      :class="{ 'font-weight-bold': item.bold }"
                      >{{ item.text }}</span
                    >
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>

              <help
                bg-color="#fff"
                help-id="52"
              ></help>
            </v-row>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableAreasHeaders"
          :items="tableAreas"
          :mobile-breakpoint="0"
          :loading="tableAreasLoading"
          :items-per-page="-1"
          hide-default-footer
          @click:row="goToDetail"
          class="table-action row-is-clickable"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'LibraryAreasDetail',
                params: { theaterAreaId: item.id },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>


        </v-data-table>

        <!-- Tabel settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          no-gutters
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableAreas.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableAreas.length > 25"
              @click="tableAreasPaging.itemsPerPage = 25"
              :color="tableAreasPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableAreas.length > 25"
              @click="tableAreasPaging.itemsPerPage = 50"
              :color="tableAreasPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableAreas.length > 50"
              @click="tableAreasPaging.itemsPerPage = 100"
              :color="tableAreasPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableAreasPaging.pageCount > 1"
              v-model="tableAreasPaging.page"
              :length="tableAreasPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table downlaod -->
          <v-col
            cols="12"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableAreas"
                :columns="downloadExportColumns"
                :filename="'dip-zalen-export'"
                :sheetname="'Zalen'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryAreas',
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      title: 'Zalen',
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: true,
        },
      ],
      areas: [],
      tableAreas: [],
      tableAreasFiltered: [],
      tableAreasLoading: true,
      tableAreasHeaders: [
        { title: 'naam', key: 'name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableAreasPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      downloadExportColumns: [
        { label: 'zaalID', field: 'id' },
        { label: 'zaalnaam', field: 'name' },
        { label: 'intern', field: 'onPremise' },
        { label: 'straat', field: 'street' },
        { label: 'huisnummer', field: 'number' },
        { label: 'postcode', field: 'zipCode' },
        { label: 'plaats', field: 'city' },
        { label: 'provincie', field: 'provinceName' },
        { label: 'theater', field: 'theaterName' },
        { label: 'theaterID', field: 'theaterId' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreas');
    this.getAreas();
  },
  methods: {
    goToDetail(row) {
      this.$router.push({
        name: 'LibraryAreasDetail',
        params: { theaterAreaId: row.id },
      });
    },
    getAreas: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };

      ApiService.get('/TheaterAreas/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.areas = response.data.result;

          for (var i = 0; i < this.areas.length; i++) {
            vmLegacy.$set(this.areas[i], 'provinceName', this.areas[i].province.name);
            vmLegacy.$set(this.areas[i], 'theaterName', this.areas[i].theater.name);
            if (this.areas[i].onPremise === true) {
              this.areas[i].onPremise = 'Ja';
            } else {
              this.areas[i].onPremise = 'Nee';
            }
          }

          this.tableAreas = this.areas;
          this.tableAreasLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
