<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1122"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
                class="pb-0"
              >
                <h1 class="primary--text">kaartstanden bijwerken</h1>
              </v-col>
              <v-col
                v-if="ticketLinkType == 1"
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="ticketSyncFormDownloadValid"
                  class="d-flex flex-column"
                  ref="formDownload"
                >
                  <p class="primary--text mb-10">
                    Onder de kop "Kaartstanden downloaden" haalt u het formulier op waarmee u kaartstanden kunt
                    doorgeven.
                    <br />Werk in het formulier de kaartstanden bij, sla dit op en upload het ingevulde formulier om de
                    kaartstanden te verwerken.
                    <br />
                    <br /><strong>Let op! Alle bedragen zijn in centen!</strong>
                  </p>

                  <h4 class="primary--text mb-2 font-weight-regular">kaartstanden downloaden</h4>
                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          label="voorstelling datum vanaf"
                          placeholder="DD-MM-JJJJ"
                          v-model="ticketSyncFormDownload.dateFrom"
                          :rules="formValidationRules.date"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          label="voorstelling datum tot"
                          placeholder="DD-MM-JJJJ"
                          v-model="ticketSyncFormDownload.dateTo"
                          :rules="formValidationRules.date"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-btn
                          rounded
                          large
                          elevation="0"
                          @click="submitDownload"
                          :loading="ticketSyncFormDownloadLoading"
                          color="secondary"
                          class="text-lowercase mb-2 align-self-start"
                          >Download
                          <v-icon
                            small
                            right
                            >mdi-download</v-icon
                          ></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-alert
                          v-if="ticketSyncFormDownloadError != ''"
                          text
                          color="error"
                          >{{ ticketSyncFormDownloadError }}</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>

                <v-form
                  v-if="rights.edit"
                  @submit.prevent
                  v-model="ticketSyncFormUpdateValid"
                  class="d-flex flex-column"
                  ref="formUpdate"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">bijgewerkte kaartstanden inladen</h4>
                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-file-input
                          label="bestand"
                          truncate-length="15"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          v-model="ticketSyncFormUpdate.file"
                          :rules="formValidationRules.required"
                          outlined
                          dense
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-btn
                          rounded
                          large
                          elevation="0"
                          @click="submitUpdate"
                          :loading="ticketSyncFormUpdateLoading"
                          color="secondary"
                          class="text-lowercase mb-2 align-self-start"
                          >Opslaan
                          <v-icon
                            small
                            right
                            >mdi-content-save</v-icon
                          ></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-alert
                          v-if="ticketSyncFormUpdateError != ''"
                          text
                          color="error"
                          >{{ ticketSyncFormUpdateError }}</v-alert
                        >
                        <v-alert
                          v-if="ticketSyncFormUpdateSuccess != ''"
                          text
                          color="success"
                          >{{ ticketSyncFormUpdateSuccess }}</v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-col>
              <v-col
                v-else-if="ticketLinkType == 2"
                cols="12"
                md="6"
                sm="10"
              >
                <p class="primary--text mb-10">
                  De kaartstanden worden automatisch bijgewerkt door middel van de API.
                  <br />Voor vragen neem contact op met DIP.
                </p>
              </v-col>
              <v-col
                v-else-if="ticketLinkType == 3"
                cols="12"
                md="6"
                sm="10"
              >
                <p class="primary--text mb-10">
                  De kaartstanden worden automatisch bijgewerkt door Ticketmatic.
                  <br />Voor vragen neem contact op met DIP.
                </p>
              </v-col>
              <v-col
                v-else
                cols="12"
                md="6"
                sm="10"
              >
                <p class="primary--text mb-10">
                  De instellingen voor kaartstanden bijwerken zijn nog niet goed ingesteld.
                  <br />Voor vragen neem contact op met DIP.
                </p>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryTicketSync',
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,

      ticketLinkType: 0,
      ticketClientName: '',

      ticketSyncFormDownloadValid: false,
      ticketSyncFormDownloadError: '',
      ticketSyncFormDownloadLoading: false,
      ticketSyncFormDownload: {
        dateFrom: '',
        dateTo: '',
      },
      ticketSyncFormUpdateValid: false,
      ticketSyncFormUpdateError: '',
      ticketSyncFormUpdateSuccess: '',
      ticketSyncFormUpdateLoading: false,
      ticketSyncFormUpdate: {
        file: null,
      },

      ticketSyncFormDownloadIdNumbersError: '',
      ticketSyncFormDownloadIdNumbersLoading: false,

      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'kaartstanden bijwerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryTicketSync');
    this.formValidationRules = functions.getFormValidationRules();
    this.getTheater();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getTheater() {
      if (this.user.organisationTypeId == 2) {
        ApiService.get('/Theaters/Get', {
          theaterId: this.user.organisationId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              const theater = response.data.result;

              this.ticketLinkType = theater.ticketLinkType;
              this.ticketClientName = theater.ticketClientName;
            }
          })
          .catch((error) => {
            console.error(error);
            this.errored = true;
          });
      }
    },
    submitDownload() {
      this.ticketSyncFormDownloadError = '';
      this.ticketSyncFormDownloadLoading = true;
      if (this.ticketSyncFormDownloadValid) {
        const form = this.ticketSyncFormDownload;
        ApiService.getFile('/Files/Download', {
          StartDate: form.dateFrom ? functions.getDateDatabase(form.dateFrom) : '',
          EndDate: form.dateTo ? functions.getDateDatabase(form.dateTo) : '',
        })
          .then((response) => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('target', '_blank');
            fileLink.setAttribute('download', 'kaartstanden.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            this.ticketSyncFormDownloadLoading = false;
          })
          .catch(() => {
            this.ticketSyncFormDownloadError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.ticketSyncFormDownloadLoading = false;
          });
      } else {
        this.ticketSyncFormDownloadLoading = false;
        this.$refs.formDownload.validate();
      }
    },
    submitUpdate() {
      this.ticketSyncFormUpdateError = '';
      this.ticketSyncFormUpdateSuccess = '';
      this.ticketSyncFormUpdateLoading = true;
      if (this.ticketSyncFormUpdateValid) {
        const form = this.ticketSyncFormUpdate;

        const formData = new FormData();
        formData.append('file', form.file);

        ApiService.putFile('/Files/Upload', formData)
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.ticketSyncFormUpdateLoading = false;
              this.ticketSyncFormUpdate.file = null;
              this.$refs.formUpdate.reset();

              this.ticketSyncFormUpdateSuccess =
                'Bedankt voor het doorgeven van de kaartstanden! De standen worden nu verwerkt en zouden na enkele minuten zichtbaar moeten zijn in DIP.';
            } else {
              this.ticketSyncFormUpdateError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            }
            this.ticketSyncFormUpdateLoading = false;
          })
          .catch(() => {
            this.ticketSyncFormUpdateError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.ticketSyncFormUpdateLoading = false;
          });
      } else {
        this.ticketSyncFormUpdateLoading = false;
        this.$refs.formUpdate.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>