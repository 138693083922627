<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <Help
              bg-color="#fff"
              help-id="40"
            />
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableRightsFilter.name"
                label="op naam zoeken"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              />
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableRightsFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                  >
                    filter overzicht
                    <v-icon
                      small
                      right
                    >mdi-filter</v-icon
                    >
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="primary--text">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableRightsFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    />
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableRightsFilterDialog = false"
                    >
                      filter overzicht
                      <v-icon
                        small
                        right
                      >mdi-filter</v-icon
                      >
                    </v-btn>
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterUsers"
                    >
                      reset filter
                      <v-icon
                        small
                        right
                      >mdi-close</v-icon
                      >
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableRightsHeaders"
          :items="tableRightsFiltered"
          :mobile-breakpoint="0"
          :loading="tableRightsLoading"
          :page="tableRightsPaging.page"
          :items-per-page="tableRightsPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableRightsPaging.pageCount = Math.ceil(tableRightsFiltered.length / tableRightsPaging.itemsPerPage)"
          @update:items-per-page="tableRightsPaging.pageCount = Math.ceil(tableRightsFiltered.length / tableRightsPaging.itemsPerPage)"
          class="table-action table-expand"
          item-key="name"
          show-expand
          :expanded.sync="tableRightsExpanded"
          fixed-header
          :height="tableRightsFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }">
            <v-btn
              v-if="adminCanEdit"
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{ name: 'AdminRightsEdit', params: { userRoleId: item.id } }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              @click="expand(!isExpanded)"
            >
              <v-icon
                v-if="isExpanded"
                color="primary"
              >mdi-chevron-down</v-icon
              >
              <v-icon
                v-else
                color="primary"
              >mdi-chevron-up</v-icon
              >
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-data-table
                :headers="tableRightsDetailHeaders"
                :items="item.sitemap"
                :mobile-breakpoint="0"
                :items-per-page="-1"
                hide-default-footer
                class="table-action"
              >
                <template v-slot:[`item.rights.view`]="{ item }">
                  <v-checkbox
                    v-if="item.rights.view != null"
                    :input-value="item.rights.view"
                    value
                    disabled
                  />
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.edit`]="{ item }">
                  <v-checkbox
                    v-if="item.rights.edit != null"
                    :input-value="item.rights.edit"
                    value
                    disabled
                  />
                  <div v-else>-</div>
                </template>
                <template v-slot:[`item.rights.approve`]="{ item }">
                  <v-checkbox
                    v-if="item.rights.approve != null"
                    :input-value="item.rights.approve"
                    value
                    disabled
                  />
                  <div v-else>-</div>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ tableRightsFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableRightsFiltered.length > 25"
              @click="tableRightsPaging.itemsPerPage = 25"
              :color="tableRightsPaging.itemsPerPage === 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
            >25</v-btn>
            <v-btn
              v-if="tableRightsFiltered.length > 25"
              @click="tableRightsPaging.itemsPerPage = 50"
              :color="tableRightsPaging.itemsPerPage === 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
            >50</v-btn>
            <v-btn
              v-if="tableRightsFiltered.length > 50"
              @click="tableRightsPaging.itemsPerPage = 100"
              :color="tableRightsPaging.itemsPerPage === 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
            >100</v-btn>
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableRightsPaging.pageCount > 1"
              v-model="tableRightsPaging.page"
              :length="tableRightsPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminRights',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'rechten',
          disabled: false,
          href: '/admin/rechten',
          bold: true,
        },
      ],
      userRoles: [],
      tableRightsExpanded: [],
      tableRightsFiltered: [],
      tableRightsLoading: true,
      tableRightsHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'organisatie type', key: 'organisationTypeName' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableRightsFilter: {
        roleName: '',
      },
      tableRightsPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableRightsFilterDialog: false,
      tableRightsDetailHeaders: [
        { title: 'module', key: 'name', sortable: false },
        { title: 'inzage', key: 'rights.view' },
        { title: 'beheer', key: 'rights.edit' },
        { title: 'accorderen', key: 'rights.approve' },
      ],
      adminCanEdit: false,
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableRightsFilter });
    this.tableRightsFilter = this.getFilter(this.$options.name);

    this.canEdit();

    this.getUsersroles();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    canEdit: function () {
      const usersCanEdit = [
        1043, // Marlène Colbers (DIP)
        877, // Roel Cuppen (Cre8ion)
        878, // Sven Vermeulen (Cre8ion)
        879, // Sander van de Rijt (Cre8ion)
        880, // Joris van Loon (Cre8ion)
        1308, // Bas van Hout (Cre8ion)
      ];

      if (usersCanEdit.includes(this.user.userId)) {
        this.adminCanEdit = true;
      }
    },

    getUsersroles: function () {
      ApiService.get('/Rights/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.userRoles = response.data.result.userRoles;
          this.tableRightsFiltered = this.userRoles;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableRightsPaging });
          this.tableRightsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableRightsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableRightsFiltered = this.userRoles.filter(function (userrole) {
        return cleanWords(userrole.name).indexOf(cleanWords(vm.tableRightsFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableRightsFilter.roleName = '';
      this.tableRightsFilterDialog = false;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableRightsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableRightsPaging });
  },
  watch: {
    tableRightsFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
