<template>
  <v-container fluid>
    <v-row v-if="!this.user.hasPublicMonitorAccess">
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0 pl-4">
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help bg-color="#fff" help-id="1148"></help>
          </v-col>
        </v-row>

        <v-row flex justify="center">
          <v-col cols="12" md="10" sm="10">
            <h1 class="primary--text mb-5">Oeps! Je bent nog niet aangesloten op de Publieksmonitor.</h1>
            <p class="primary--text mb-5">

              De Publieksmonitor is een digitale tool voor het centraal verzamelen en ontsluiten van publieks- en verkoopdata in de podiumkunstensector. Hiermee kunnen programmeurs, marketeers én makers data-gedreven, weloverwogen besluiten nemen. <br><br>

              Aansluiten gebeurt niet automatisch
              Misschien denk je: ik ben al DIP-gebruiker, dan heb ik automatisch toch ook de Publieksmonitor? Niet helemaal. Aansluiting op de Publieksmonitor gaat namelijk via een aparte koppeling met een zogenoemde ‘loader’. <br><br>
              <ul>
                <li>Werk je met Ticketmatic, Ticketworks, Itix of Active Tickets? Top! Daar hebben we namelijk al een loader voor, waardoor we jouw koppeling snel kunnen realiseren.</li>
                <li>Werk je met een ander kaartverkoopsysteem, maar wil je wel graag aansluiten? Laat het ons weten. We proberen zo snel mogelijk alle nodige loaders te ontwikkelen, zodat iedereen de Publieksmonitor kan gebruiken.</li>
              </ul>
            </p>
            <v-btn
              color="secondary"
              rounded
              depressed
              href="https://www.dip.nl/publieksmonitor"
              target="blank"
              class="font-weight-bold text-lowercase"
            >
              of lees hier meer over de Publieksmonitor
            </v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-row flex align="center" justify="space-between" class="pa-2">
          <v-col></v-col>
          <v-col class="d-flex align-center justify-end flex-grow-0">
            <v-btn
              color="primary"
              outlined
              rounded
              depressed
              class="font-weight-bold text-lowercase ms-2"
              :to="{ name: 'PublicMonitorReports' }"
            >
              rapportages
            </v-btn>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase ms-2"
            >
              producties
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0 pl-4">
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help bg-color="#fff" help-id="1148"></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period
                :includeOptions="[
                  'thisWeek',
                  'thisSeason',
                  'nextSeason',
                  'prevSeason',
                ]"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
              <v-text-field
                v-model="tablePublicMonitorFilter.productionName"
                label="productie zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tablePublicMonitorFilter.genre"
                :items="genres"
                label="genre selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tablePublicMonitorFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"
                    ><h5 class="primary--text">
                      filter overzicht
                    </h5></v-card-title
                  >

                  <v-card-text>
                    <filter-period
                      :includeOptions="[
                        'thisWeek',
                        'thisSeason',
                        'nextSeason',
                        'prevSeason',
                      ]"
                      selectedDefault="thisSeason"
                      @changePeriod="filterPeriod = $event"
                    ></filter-period>
                    <v-text-field
                      v-model="tablePublicMonitorFilter.productionName"
                      label="productie zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tablePublicMonitorFilter.genre"
                      :items="genres"
                      label="genre selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tablePublicMonitorFilterDialog = false"
                      >filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterProductions()"
                      >reset filter
                      <v-icon small right>mdi-close</v-icon></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <public-monitor-productions-table
        :headers="tablePublicMonitorHeadersFiltered"
        :items="tablePublicMonitorFiltered"
        :loading="tablePublicMonitorLoading"
        :pageSync="tablePublicMonitorPage"
        :filter-active="tablePublicMonitorFilterActive"
        :selected="tablePublicMonitorSelected"
        :total="tablePublicMonitorTotal"
        :downloads="this.user.organisationTypeId != 2 ? downloadExportColumns : downloadExportColumnsTheater"
        :detailPage="'PublicMonitorProductionsDetail'">
      </public-monitor-productions-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import ApiService from "../../services/api.service";
import { TokenService } from "../../services/storage.service";
import Help from "../../components/Help.vue";
import functions from "../../services/functions.service";
import FilterPeriod from "../../components/FilterPeriod";
import PublicMonitorProductionsTable from "./components/ProductionOverview/ProductionsTable.vue"

export default {
  name: "PublicMonitorProductions",
  components: {
    Help,
    FilterPeriod,
    PublicMonitorProductionsTable
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: "publieksmonitor",
          disabled: false,
          href: "",
          bold: true,
        },
      ],
      filterPeriod: {
        from: "",
        to: "",
      },
      productions: [],
      tablePublicMonitorSelected: {
        performancesCount: 0,
        sold: 0,
        capacity: 0,
        purchases: 0,
      },
      tablePublicMonitorTotal: {
        performancesCount: 0,
        sold: 0,
        capacity: 0,
        purchases: 0,
      },
      tablePublicMonitorFiltered: [],
      selectAgencyId: "",
      genres: [],
      tablePublicMonitorLoading: true,
      tablePublicMonitorHeaders: [
        { title: "naam", key: "title" },
        { title: "datum en tijd", key: "startDate" },
        { title: "verkocht", key: "tickets" },
        { title: "aantal vrst.", key: "performances" },
        { title: "capaciteit", key: "capacity" },
        { title: "aantal kaartkopers", key: "purchases" },
        { title: "genre", key: "genre" },
        { title: "", key: "action", sortable: false, align: "end" },
      ],
      tablePublicMonitorFilterActive: false,
      tablePublicMonitorFilter: {
        productionName: "",
        genre: "",
      },
      tablePublicMonitorPage: 1,
      tablePublicMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: "naam", field: "title" },
        { label: "DIP-ID", field: "number" },
        { label: "datum en tijd", field: "startDate" },
        { label: "verkocht", field: "tickets" },
        { label: "aantal vrst.", field: "performances" },
        { label: "capaciteit", field: "capacity" },
        { label: "aantal kaartkopers", field: "purchases" },
        { label: "genre", field: "genre" },
      ],
      downloadExportColumnsTheater: [
        { label: "naam", field: "title" },
        { label: "DIP-ID", field: "id" },
        { label: "datum en tijd", field: "startDate" },
        { label: "verkocht", field: "tickets" },
        { label: "aantal vrst.", field: "performances" },
        { label: "capaciteit", field: "capacity" },
        { label: "aantal kaartkopers", field: "purchases" },
        { label: "genre", field: "genre" },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights("SalesMonitor");
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tablePublicMonitorFilter,
    });
    this.tablePublicMonitorFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions("storage", ["checkFilter", "updateFilter"]),
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat("nl-NL").format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      const vm = this; const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.productions = [];
      this.tablePublicMonitorFiltered = [];
      this.tablePublicMonitorLoading = true;

      ApiService.get("PublicMonitor/GetProductions", {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.productions = response.data.result;

          for (var i = 0; i < this.productions.length; i++) {
            vmLegacy.$set(
              this.productions[i],
              "startDateView",
              functions.getDateTimeView(this.productions[i].startDate)
            );

            vm.genres.push({
              value: this.productions[i].genre,
              text: this.productions[i].genre,
            });
          }

          this.tablePublicMonitorTotal.performancesCount =
            this.calculateArraySum(this.productions, "performances");
          this.tablePublicMonitorTotal.sold = this.calculateArraySum(
            this.productions,
            "tickets"
          );
          this.tablePublicMonitorTotal.capacity = this.calculateArraySum(
            this.productions,
            "capacity"
          );

          this.tablePublicMonitorTotal.purchases = this.calculateArraySum(
            this.productions,
            "purchases"
          );

          this.tablePublicMonitorFiltered = this.productions;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + "-paging",
            filterObject: this.tablePublicMonitorPage,
          });
          this.tablePublicMonitorPage = this.getFilter(
            this.$options.name + "-paging"
          );

          this.filterProductions();
          this.tablePublicMonitorLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;

      if (
        this.tablePublicMonitorFilter.genre == "" ||
        this.tablePublicMonitorFilter.genre == undefined
      ) {
        this.tablePublicMonitorFilterActive = false;
      } else {
        this.tablePublicMonitorFilterActive = true;
      }

      this.tablePublicMonitorFiltered = this.productions.filter(function (
        production
      ) {
        return (
          cleanWords(production.title).indexOf(
            cleanWords(vm.tablePublicMonitorFilter.productionName)
          ) !== -1 &&
          (production.genre == vm.tablePublicMonitorFilter.genre ||
            vm.tablePublicMonitorFilter.genre == "" ||
            vm.tablePublicMonitorFilter.genre == undefined)
        );
      });

      this.tablePublicMonitorSelected.performancesCount =
        this.tablePublicMonitorSelected.performancesCount =
          this.calculateArraySum(
            this.tablePublicMonitorFiltered,
            "performances"
          );

      this.tablePublicMonitorSelected.sold = this.calculateArraySum(
        this.tablePublicMonitorFiltered,
        "tickets"
      );

      this.tablePublicMonitorSelected.capacity = this.calculateArraySum(
        this.tablePublicMonitorFiltered,
        "capacity"
      );

      this.tablePublicMonitorSelected.purchases = this.calculateArraySum(
        this.tablePublicMonitorFiltered,
        "purchases"
      );

      this.calculateArraySum(this.tablePublicMonitorFiltered, "performances");

      function cleanWords(word) {
        if (word == undefined) {
          return "";
        }
        return word
          .normalize("NFD")
          .replace(/[^a-zA-Z 0-9]/g, "")
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tablePublicMonitorFilter.genre = "";
      this.tablePublicMonitorFilterActive = false;
      this.tablePublicMonitorFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["getComponentRights"]),
    ...mapState("auth", ["user"]),
    ...mapGetters("storage", ["getFilter"]),

    tablePublicMonitorHeadersFiltered() {
      return this.tablePublicMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tablePublicMonitorFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + "-paging",
      filterObject: this.tablePublicMonitorPage,
    });
  },
  watch: {
    tablePublicMonitorFilter: {
      handler: "filterProductions",
      deep: true,
    },
    filterPeriod: function () {
      this.getProductions();
    },
  },
};
</script>
