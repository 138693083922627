import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Auth/Login.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import TwoFactorAuth from '../views/Auth/TwoFactorAuth.vue';
import Account from '../views/Account/Index.vue';
import store from '../store/index';

const createRouterInstance = () => {
  return createRouter({
    history: createWebHistory('/'),
    routes: [],
  });
};

function defaultRoutes(router) {
  router.addRoute({
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/wachtwoord-vergeten',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/wachtwoord-instellen/:token',
    name: 'resetPassword',
    component: ResetPassword,
    props: true,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/twofactorauth',
    name: 'twofactorauth',
    component: TwoFactorAuth,
    props: true,
    meta: {
      public: true,
      onlyWhenLoggedOut: false,
    },
  });

  router.addRoute({
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
    },
  });
}

const router = createRouterInstance();
defaultRoutes(router);

router.beforeEach((to, from, next) => {
  const isPublic = to.meta.public;
  const onlyWhenLoggedOut = to.meta.onlyWhenLoggedOut;
  const loggedIn = store.state.auth.loggedIn;

  if (!isPublic && !loggedIn) {
    if (to.fullPath === '/') {
      return next({ path: '/login' });
    } else {
      return next({
        path: '/login',
        query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
      });
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  next();
});

export function resetRouter() {
  const newRouter = createRouterInstance();
  router.matcher = newRouter.matcher;
  defaultRoutes(router);
}

export default router;