<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="51"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">voorstelling bewerken</h4>
                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <div v-if="editProductionPerformanceForm.hasContract">
                          <v-tooltip
                            bottom
                            max-width="500"
                            color="primary"
                          >
                            <template v-slot:activator="{ props: activatorProps }">
                              <div
                                v-bind="activatorProps"
                              >
                                <v-autocomplete
                                  label="theater"
                                  v-model="editProductionPerformanceForm.theaterId"
                                  :items="theaters"
                                  outlined
                                  dense
                                  disabled
                                  hide-details="auto"
                                  class="mb-5"
                                >
                                </v-autocomplete>
                              </div>
                            </template>
                            <span> er is al een financiële afspraak aangemaakt voor deze voorstelling </span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <v-autocomplete
                            label="theater"
                            v-on:change="getAreas"
                            v-model="editProductionPerformanceForm.theaterId"
                            :items="theaters"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                            :disabled="editProductionPerformanceForm.hasContract"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="zalen"
                          v-model="editProductionPerformanceForm.areaId"
                          :items="areas"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="in het kader van een festival?"
                          v-model="editProductionPerformanceForm.festival"
                          required
                          outlined
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <date-picker
                          v-if="datePickerDataLoaded"
                          v-model="editProductionPerformanceForm.startDate"
                          :passedDate="editProductionPerformanceForm.startDate"
                          rules="dateTimeRequired"
                        ></date-picker>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          placeholder="UU:MM"
                          v-model="editProductionPerformanceForm.startDateTime"
                          :rules="formValidationRules.timeRequired"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="status"
                          v-model="editProductionPerformanceForm.state"
                          :items="performanceStatus"
                          :rules="formValidationRules.required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="type voorstelling"
                          v-model="editProductionPerformanceForm.type"
                          :items="performanceType"
                          :rules="formValidationRules.required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="besloten voorstelling"
                          v-model="editProductionPerformanceForm.private"
                          required
                          outlined
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          multiple
                          label="kenmerk voorstelling "
                          v-model="editProductionPerformanceForm.characteristicIds"
                          :items="characteristicsOptions"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'LibraryProductionsPerformanceEdit',
  props: ['productionNumber', 'performanceId'],
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      datePickerDataLoaded: false,
      editProductionPerformanceForm: {
        seasonId: '',
        theaterId: '',
        areaId: '',
        characteristicIds: [],
        contractId: '',
        startDate: '',
        startDateTime: '',
        dateTime: '',
        type: '',
        festival: false,
        private: false,
        state: '',
        hasContract: false,
      },
      theaters: [],
      areas: [],
      performanceStatus: [
        { text: 'interesse', value: 'interest' },
        { text: 'optie', value: 'option' },
        { text: 'geaccepteerd', value: 'agreed' },
        { text: 'succesoptie', value: 'success' },
        { text: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { text: 'normaal', value: 'normal' },
        { text: 'tryout', value: 'tryout' },
        { text: 'premiere', value: 'premiere' },
        { text: 'montage', value: 'montage' },
        { text: 'derniere', value: 'derniere' },
      ],
      characteristicsOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstelling bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceEdit');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
    this.getPerformance();
    this.getCharacteristics();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    splitDateTime: function (date) {
      if (date == null || date == '') {
        return '';
      }
      var dateArray = date.split('T');

      this.editProductionPerformanceForm.startDate = dateArray[0].split('-').reverse().join('-');
      this.editProductionPerformanceForm.startDateTime = dateArray[1].slice(0, 5);

      this.datePickerDataLoaded = true;
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;

            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({
              value: element.id,
              text: `${element.name} (${element.city}, ${element.id})`,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getAreas: function () {
      ApiService.get('/TheaterAreas/GetAll', {
        theaterId: this.editProductionPerformanceForm.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.areas = response.data.result.map((element) => ({
            value: element.id,
            text: element.name,
          }));
          if (!this.areas.map((a) => a.value).includes(this.editProductionPerformanceForm.areaId)) {
            this.editProductionPerformanceForm.areaId = '';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getPerformance: function () {
      const vm = this;

      ApiService.get('/Performances/Get', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const link = response.data.result;

            this.editProductionPerformanceForm.seasonId = link.seasonId;
            this.editProductionPerformanceForm.theaterId = link.theaterId;
            this.editProductionPerformanceForm.areaId = link.areaId;
            this.getAreas();
            this.editProductionPerformanceForm.contractId = link.contractId;
            this.splitDateTime(link.startDate);
            this.editProductionPerformanceForm.type = link.type;
            this.editProductionPerformanceForm.festival = link.isFestival;
            this.editProductionPerformanceForm.private = link.isPrivate;
            this.editProductionPerformanceForm.state = link.state;
            this.editProductionPerformanceForm.hasContract = link.hasContract;

            var characteristicIds = [];
            for (var i = 0; i < link.characteristics.length; i++) {
              characteristicIds.push(link.characteristics[i].id);
            }

            this.editProductionPerformanceForm.characteristicIds = characteristicIds;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getCharacteristics: function () {
      ApiService.get('/Characteristics/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.characteristicsOptions.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.editProductionPerformanceForm.startDate + ' ' + this.editProductionPerformanceForm.startDateTime;

        ApiService.put('/Performances/Update', {
          id: parseInt(this.performanceId),
          theaterId: form.theaterId,
          areaId: form.areaId,
          characteristicIds: form.characteristicIds,
          startDate: functions.getDateTimeDatabase(combinedDate),
          type: form.type,
          festival: form.festival,
          private: form.private,
          state: form.state,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    editProductionPerformanceForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
