<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="3"
            ></help>
          </v-col>

          <v-col
            class="py-0 d-flex align-center justify-end"
            v-if="results"
          >
            <div class="d-none d-lg-flex">
              <v-btn
                class="font-weight-bold text-lowercase ma-1"
                color="primary"
                height="40"
                @click="results = null"
                >Terug naar zoeken
                <v-icon
                  small
                  right
                  >mdi-arrow-left</v-icon
                ></v-btn
              >
            </div>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
            v-if="!results"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">zoekopties</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row class="mt-0">
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <date-picker
                          v-model="fields.dateFromString"
                          label="datum van"
                          :passedDate="null"
                          rules="date"
                        ></date-picker>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <date-picker
                          v-model="fields.dateToString"
                          label="datum tot"
                          :passedDate="null"
                          rules="date"
                        ></date-picker>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="naam theater"
                          v-model="fields.theaterName"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="id theater"
                          v-model="fields.theaterId"
                          :rules="formValidationRules.number"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="naam producent"
                          v-model="fields.producerId"
                          :items="producers"
                          outlined
                          dense
                          @change="getProductions"
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="id producent"
                          v-model="fields.producerId"
                          :rules="formValidationRules.number"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="naam impresariaat"
                          v-model="fields.agencyName"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="id impresariaat"
                          v-model="fields.agencyId"
                          :rules="formValidationRules.number"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-autocomplete
                          v-if="productions.length > 0"
                          label="titel productie"
                          v-model="fields.productionNumber"
                          :items="productions"
                          outlined
                          dense
                        ></v-autocomplete>

                        <v-text-field
                          v-else
                          class="primary--text"
                          label="titel productie"
                          v-model="fields.productionTitle"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="nummer productie"
                          v-model="fields.productionNumber"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="find"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Zoeken
                    <v-icon
                      small
                      right
                      >mdi-magnify</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-data-table
          v-if="results"
          :headers="tableHeaders"
          :items="results"
          :mobile-breakpoint="0"
          :loading="false"
          :page="tablePage"
          :items-per-page="tableItemsPerPage"
          hide-default-footer
          @update:current-items="tablePageCount = Math.ceil(results.length / tableItemsPerPage)"
          @update:items-per-page="tablePageCount = Math.ceil(results.length / tableItemsPerPage)"
          class="table-action"
          fixed-header
          :height="results.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span>{{ getDateTimeView(item.startDate) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <!-- Delete -->
            <v-btn
              fab
              x-small
              depressed
              color="error"
              v-if="!item.contractId"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
              ><v-icon color="#fff">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          v-if="results"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.results.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="results.length > 25"
              @click="tableItemsPerPage = 25"
              :color="tableItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="results.length > 25"
              @click="tableItemsPerPage = 50"
              :color="tableItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="results.length > 50"
              @click="tableItemsPerPage = 100"
              :color="tableItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tablePageCount > 1"
              v-model="tablePage"
              :length="tablePageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="results"
                :columns="downloadExportColumns"
                :filename="'dip-voorstellingen-export'"
                :sheetname="'voorstellingen'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.id }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.id }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="performDelete()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import Help from '@/components/Help';
import ApiService from '@/services/api.service';
import functions from '@/services/functions.service';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'AdminPerformances',
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      formError: '',
      formLoading: false,
      formValid: false,
      results: null,
      formValidationRules: {},
      fields: {
        dateFromString: '',
        dateToString: '',
        theaterName: '',
        theaterId: '',
        producerId: '',
        agencyName: '',
        agencyId: '',
        productionNumber: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstellingen',
          disabled: false,
          href: '/admin/voorstellingen',
          bold: true,
        },
      ],
      tablePage: 1,
      tablePageCount: 0,
      tableItemsPerPage: 25,
      tableHeaders: [
        { title: 'id', key: 'id' },
        { title: 'nummer', key: 'pkiNumber' },
        { title: 'productie', key: 'productionTitle' },
        { title: 'datum/tijd', key: 'startDate' },
        { title: 'theater', key: 'theaterName' },
        { title: 'zaal', key: 'areaName' },
        { title: 'contract-id', key: 'contractId' },
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'status', key: 'state' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      downloadExportColumns: [{ label: 'id', field: 'id' }],
      deleteItem: '',
      deleteItemDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      producers: [],
      productions: [],
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getProducers();
  },
  methods: {
    find() {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.formError = '';
      if (!this.$refs.form.validate()) {
        return;
      }
      if (
        !this.fields.dateFromString &&
        !this.fields.producerId &&
        !this.fields.theaterId &&
        !this.fields.theaterName &&
        !this.fields.agencyName &&
        !this.fields.agencyId
      ) {
        this.formError = 'vul minstens één van de volgende velden in: startdatum, producent, impresariaat of theater';
        return;
      }
      this.formLoading = true;
      this.fields.dateFrom = functions.getDateDatabase(this.fields.dateFromString);
      this.fields.dateTo = functions.getDateDatabase(this.fields.dateToString);
      ApiService.get('/Performances/Find', this.fields).then((r) => {
        this.results = r.data.result;

        for (var i = 0; i < this.results.length; i++) {
          vmLegacy.$set(this.results[i], 'state', functions.changeState(this.results[i].state));
        }

        if (this.results.length > 0) {
          this.downloadExportColumns = Object.keys(this.results[0]).map((k) => ({ label: k, field: k }));
        }
        this.formLoading = false;
      });
    },
    performDelete() {
      ApiService.delete('/Performances/Delete', {
        performanceId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.results.map((item) => item.id).indexOf(this.deleteItem.id);
            this.results.splice(i, 1);

            this.snackbarText = this.deleteItem.id + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;

            this.getContracts();
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getDateTimeView: function (date) {
      return functions.getDateTimeView(date);
    },
    getProducers: function () {
      ApiService.get('/Productions/GetProducers').then(
        (response) =>
          (this.producers = response.data.result.map((r) => ({
            value: r.id,
            text: r.name,
          })))
      );
    },
    getProductions: function () {
      ApiService.get('/Productions/GetPerProducer', {
        ProducerId: this.fields.producerId,
        SeasonId: 0,
      }).then((response) => {
        let result = response.data.result;
        let sortedProductionsArray = [];

        sortedProductionsArray = result.map((element) => ({
          value: element.number,
          text: element.title,
        }));

        sortedProductionsArray.sort(function (a, b) {
          var textA = a.text.toUpperCase();
          var textB = b.text.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.productions = sortedProductionsArray;
      });
    },
  },
};
</script>
