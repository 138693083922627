<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="39"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    v-model="formValid"
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h1 class="primary--text mb-5">{{ this.notificationname }}</h1>
                    <h4 class="primary--text mb-5">gegevens notificatie</h4>

                    <v-card
                      class="pa-6 mb-1"
                      flat
                    >
                      <v-row class="mt-0">
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Titel -->
                          <v-text-field
                            class="primary--text"
                            label="titel"
                            v-model="editNotificationForm.title"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Selecteer datum -->
                          <v-text-field
                            v-model="editNotificationForm.date"
                            label="datum"
                            placeholder="DD-MM-JJJJ UU:MM"
                            :rules="formValidationRules.dateTimeRequired"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Organisatie type -->
                          <v-autocomplete
                            label="bestemd voor"
                            v-model="editNotificationForm.organisationTypeId"
                            :items="organisationTypeOptions"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Tekst -->
                          <v-textarea
                            class="primary--text"
                            label="tekst"
                            v-model="editNotificationForm.text"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card>

                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.lastEditedBy }}</i></small
                      >
                    </p>

                    <v-alert
                      v-if="formError != ''"
                      text
                      color="error"
                      >{{ formError }}</v-alert
                    >

                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="submit"
                      :loading="formLoading"
                      color="secondary"
                      class="text-lowercase mb-5 align-self-start"
                      >Opslaan
                      <v-icon
                        small
                        right
                        >mdi-content-save</v-icon
                      ></v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminNotificationsEdit',
  props: ['notificationId'],
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      date: null,
      menu: false,
      editNotificationForm: {
        title: '',
        text: '',
        date: '',
        organisationTypeId: '',
      },
      lastEditedBy: '',
      organisationTypeOptions: [],
      formValidationRules: {},
      notificationname: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/admin/notificaties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getNotification();
    this.getOrganisationTypes();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getNotification: function () {
      const vm = this;

      ApiService.get('/Notifications/Get', {
        id: this.notificationId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const notification = response.data.result;

            this.breadcrumbs[2].text = notification.title;
            this.breadcrumbs[2].href = '/admin/notificaties/' + notification.id;
            this.notificationname = notification.title;
            this.editNotificationForm.title = notification.title;
            this.editNotificationForm.date = functions.getDateTimeView(notification.showDate);
            this.editNotificationForm.text = notification.text;
            this.editNotificationForm.organisationTypeId = notification.organisationTypeId;
            this.lastEditedBy = notification.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getOrganisationTypes: function () {
      ApiService.get('/OrganisationTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.organisationTypeOptions.push({ value: 0, text: 'Iedereen' });

          response.data.result.forEach((element) => {
            this.organisationTypeOptions.push({
              value: element.organisationTypeId,
              text: element.organisationTypeName,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editNotificationForm;

        ApiService.put('/Notifications/Update', {
          id: this.notificationId,
          title: form.title,
          text: form.text,
          organisationTypeId: form.organisationTypeId,
          showDate: functions.getDateTimeDatabase(form.date),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editNotificationForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
