<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="26"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableGenresFilter.name"
                label="op naam zoeken"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableGenresFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableGenresFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableGenresFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterGenres()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableGenresHeaders"
          :items="tableGenresFiltered"
          :mobile-breakpoint="0"
          :loading="tableGenresLoading"
          :page="tableGenresPaging.page"
          :items-per-page="tableGenresPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableGenresPaging.pageCount = Math.ceil(tableGenresFiltered.length / tableGenresPaging.itemsPerPage)"
          @update:items-per-page="tableGenresPaging.pageCount = Math.ceil(tableGenresFiltered.length / tableGenresPaging.itemsPerPage)"
          class="table-action"
          fixed-header
          :height="tableGenresFiltered.length > 10 ? '60vh' : ''"
        >
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          v-if="!tableGenresLoading"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableGenresFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableGenresFiltered.length > 25"
              @click="tableGenresPaging.itemsPerPage = 25"
              :color="tableGenresPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableGenresFiltered.length > 25"
              @click="tableGenresPaging.itemsPerPage = 50"
              :color="tableGenresPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableGenresFiltered.length > 50"
              @click="tableGenresPaging.itemsPerPage = 100"
              :color="tableGenresPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableGenresPaging.pageCount > 1"
              v-model="tableGenresPaging.page"
              :length="tableGenresPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableGenresFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-genres-export'"
                :sheetname="'Genres'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminGenres',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'genres',
          disabled: false,
          href: '/admin/genres',
          bold: true,
        },
      ],
      genres: [],

      tableGenresFiltered: [],
      tableGenresLoading: true,
      tableGenresHeaders: [
        { title: 'id', key: 'id' },
        { title: 'naam', key: 'name' },
      ],
      tableGenresFilter: {
        name: '',
      },
      tableGenresPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableGenresFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableGenresFilter });
    this.tableGenresFilter = this.getFilter(this.$options.name);

    this.getGenres();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    getGenres: function () {
      ApiService.get('/Genres/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.genres = response.data.result;
          this.tableGenresFiltered = this.genres;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableGenresPaging });
          this.tableGenresPaging = this.getFilter(this.$options.name + '-paging');

          this.filterGenres();

          this.tableGenresLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterGenres: function () {
      const vm = this;
      this.tableGenresFiltered = this.genres.filter(function (genre) {
        return cleanWords(genre.name).indexOf(cleanWords(vm.tableGenresFilter.name)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterGenres: function () {
      this.tableGenresFilter.name = '';
      this.tableGenresFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableGenresFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableGenresPaging });
  },
  watch: {
    tableGenresFilter: {
      handler: 'filterGenres',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
