import ApiService from './api.service';
import { TokenService } from './storage.service';
import router, { resetRouter } from '../router/router.js';
import { viewService } from './view.service';
import store from '../store';

const UserService = {
  async login(email, password) {
    const requestData = {
      method: 'post',
      url: '/Account/Login',
      data: {
        email: email,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (!response.data.success) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();
        
        if (!response.data.result.twoFactorEnabled) {
          await UserService.setNavigation();
        } else {
          store.dispatch('auth/fetchUser', { twoFactorEnabled: true });
        }
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },

  logout() {
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    UserService.clearNavigation();

    return TokenService.removeToken();
  },

  async twoFactorAuth(authCode) {
    const requestData = {
      method: 'post',
      url: '/Account/ValidateTwoFactorAuthentication',
      data: {
        CustomerKey: 'two-factor',
        ValidationKey: authCode,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (!response.data.success) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();
        await UserService.setNavigation();
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },

  async switchRole(userId, userRoleId, organisationId, organisationTypeId) {

    try {
      const response = await ApiService.post('/Account/SetRole', {
        userId: userId,
        userRoleId: userRoleId,
        organisationId: organisationId,
        organisationTypeId: organisationTypeId,
      });

      TokenService.saveToken(response.data.token);
      ApiService.setHeader();
      ApiService.mount401Interceptor();

      UserService.clearNavigation();
      UserService.setNavigation();

      const redirect = setInterval(() => {
        if (store.state.auth.user.agreedToTerms !== undefined) {
          clearInterval(redirect);

          if (!store.state.auth.user.agreedToTerms) {
            router.push('/accepteer-voorwaarden');
          } else if (userRoleId === 0 && organisationId === 0 && organisationTypeId === 0) {
            router.push('/');
          } else {
            router.push('/dashboard/');
          }

          store.dispatch('auth/fetchAuthenticating');
        }
      }, 100);
    } catch (error) {
      console.error(error);
      router.back();
    }
  },

  async setNavigation() {

    store.dispatch('auth/fetchNavigationStatus');

    try {
      const response = await ApiService.get('/Account/GetNavigation', {
        status: store.getters['auth/getNavigationStatus'],
      });

      const json = response.data.result;
      addRoutes(json.navigation);
      store.dispatch('auth/fetchNavigation', json.navigation);
      store.dispatch('auth/fetchUser', json.user);

      if (!json.user.agreedToTerms) {
        router.addRoute({
          path: '/accepteer-voorwaarden',
          component: viewService.getView('AcceptTerms'),
          name: 'AcceptTerms',
          props: false,
        });
        router.push('/accepteer-voorwaarden');
      }

      router.addRoute({
        path: '/:catchAll(.*)',
        component: viewService.getView('PageNotFound'),
        name: 'PageNotFound',
        props: false,
      });
      router.replace(router.currentRoute);
    } catch (error) {
      store.dispatch('auth/logout');
      console.error(error);
    }
  },

  clearNavigation() {
    resetRouter();
  },
};

function addRoutes(routes) {
  routes.forEach(route => {
    if (route.router.component) {
      const newRoute = {
        path: route.router.path,
        component: viewService.getView(route.router.component),
        name: route.router.name,
        props: route.router.props,
      };
      router.addRoute(newRoute);
    }
    if (route.children) {
      addRoutes(route.children);
    }
  });
}

export default UserService;