import { createVuetify } from 'vuetify';
import 'vuetify/lib/styles/main.sass';

// Import translations for Vuetify
import nl from 'vuetify/lib/locale/nl';
import { VImg, VIcon, VList, VListItem, VContainer, VBtn, VApp, VListGroup, VNavigationDrawer, VSpacer, VMenu, VAppBar, VMain, VCardTitle, VCardText, VCardActions, VDialog, VTextField, VAlert, VForm, VCol, VCard, VRow, VLayout,  VListItemTitle, VBreadcrumbs, VBreadcrumbsItem, VTextarea, VProgressLinear, VChip, VDataTable, VPagination, VAutocomplete, VTooltip, VSnackbar, VDatePicker, VCheckbox, VSelect, VExpansionPanels, VExpansionPanel, VExpansionPanelTitle, VExpansionPanelText } from 'vuetify/lib/components';

// Create Vuetify instance
const vuetify = createVuetify({
    components:{
        VImg, VIcon, VList, VListItem, VContainer, VBtn, VApp, VListGroup, VNavigationDrawer, VSpacer, VMenu, VAppBar, VMain, VCardTitle, VCardText, VCardActions, VDialog, VTextField, VAlert, VForm, VCol, VCard, VRow, VLayout, VListItemTitle, VBreadcrumbs, VBreadcrumbsItem, VTextarea, VProgressLinear, VChip, VDataTable, VPagination, VAutocomplete, VTooltip, VSnackbar, VDatePicker, VCheckbox, VSelect, VExpansionPanels, VExpansionPanel, VExpansionPanelTitle, VExpansionPanelText
    },

  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#021e72',
          secondary: '#0abfb8',
          accent: '#e1e7f2',
          error: '#d92853',
          info: '#f4f7fc',
          success: '#0abfb8',
          warning: '#fff453',
        },
      },
    },
  },
});

export default vuetify;