<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="openContractsLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">
              Openstaande financiële afspraken
            </p>
            <Help
              v-if="helpId"
              :help-id="helpId"
              bg-color=""
            />
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-list
                flat
                v-if="contracts.length"
              >
                <v-list-item
                  v-for="(item, i) in contracts"
                  :key="i"
                  link
                  :to="item.link"
                  dense
                >
                  <v-list-item-title
                    v-text="item.productionTitle"
                    class="primary--text"
                  />
                  <div class="my-1 v-list-item-subtitle">
                    {{ 'laatste wijziging: ' + item.startDate_formatted }}
                  </div>
                  <div class="v-list-item-subtitle counterparty pl-5">
                    {{ item.counterparty }}
                  </div>
                  <template #prepend>
                    <v-chip
                      v-for="chip in getChips(item.state)"
                      :key="chip.state"
                      :color="chip.color"
                      small
                      label
                    >
                      <strong>{{ chip.state }}</strong>
                    </v-chip>
                    <v-icon
                      color="primary"
                      class="ml-2"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </v-list-item>
              </v-list>

              <v-list v-else>
                <v-list-item>
                  <div class="primary--text v-list-item-subtitle">
                    er zijn geen openstaande contracten
                  </div>
                </v-list-item>
              </v-list>

              <div class="col col-12 pb-0">
                <v-btn
                  rounded
                  small
                  elevation="0"
                  :to="{ name: 'Contracts' }"
                  color="primary"
                  class="text-lowercase mb-2"
                >
                  alle afspraken
                </v-btn>
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">
              geen data beschikbaar
            </p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import functions from '../../services/functions.service';
import Help from '../Help.vue';

export default {
  props: {
    helpId: String,
    colsMobile: Number,
    colsTablet: Number,
    colsDesktop: Number,
  },
  components: {
    Help,
  },
  setup() {
    const store = useStore();
    const chart = ref({ loaded: false });
    const contracts = ref([]);
    const openContractsLoading = ref(true);

    // Define the state colors mapping
    const stateColors = {
      'goedgekeurd': 'success',
      'voorstel': 'accent',
      'concept': 'primary',
      'aangepast voorstel': 'warning',
      'herzien': 'warning',
      'afgekeurd': 'error'
    };

    const getData = async () => {
      chart.value.loaded = false;
      try {
        const response = await ApiService.get('/Dashboard/GetOpenContracts');
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        contracts.value = response.data.result;

        contracts.value.forEach((contract) => {
          contract.link = '/financiele-afspraken/' + contract.type + '/' + contract.id;
          contract.state = functions.getStatusNameNL(contract.state);
          contract.startDate_formatted = functions.getDateTimeView(contract.modifiedDate);
        });

        chart.value.loaded = true;
        openContractsLoading.value = false;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    onMounted(() => {
      getData();
    });

    const user = computed(() => store.state.auth.user);

    // Computed property to get the chip color based on the state
    const getChips = (state) => {
      return stateColors[state] ? [{ state, color: stateColors[state] }] : [];
    };

    // Use props directly in the template
    return {
      chart,
      contracts,
      openContractsLoading,
      user,
      getChips,
    };
  },
};
</script>

<style scoped>
.v-list-item__subtitle.my-1 {
  font-size: 13px;
  font-weight: 300;
}

.counterparty {
  font-size: 0.75rem;
  color: rgb(2, 30, 114) !important;
}
</style>