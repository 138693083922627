<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          align="center"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe gebruiker aanmaken?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminUsersAdd' }"
            >
              Aanmaken nieuwe gebruiker
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="2"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableUsersFilter.name"
                label="op naam zoeken"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableUsersFilter.email"
                label="op e-mail zoeken"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tableUsersFilter.status"
                :items="[
                  { value: 1, title: 'ja' },
                  { value: 6, title: 'nee' },
                  { value: '', title: 'ja/nee' },
                ]"
                type="text"
                label="actief"
                variant="outlined"
                hide-details
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableUsersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableUsersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableUsersFilter.email"
                      label="op e-mail zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tableUsersFilter.status"
                      :items="[
                        { value: 1, text: 'ja' },
                        { value: 6, text: 'nee' },
                        { value: '', text: 'ja/nee' },
                      ]"
                      item-text="text"
                      label="actief"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableUsersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterUsers()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableUsersHeaders"
          :items="tableUsersFiltered"
          :mobile-breakpoint="0"
          :loading="tableUsersLoading"
          :page="tableUsersPaging.page"
          :items-per-page="tableUsersPaging.itemsPerPage"
          hide-default-footer
          @update:items-per-page="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @update:current-items="tableUsersPaging.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPaging.itemsPerPage)"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableUsersFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ props: activatorProps }">
                <v-icon v-if="item.twoFactorEnabled" color="primary" v-bind="activatorProps">
                  mdi-two-factor-authentication
                </v-icon>
              </template>
              <span>Twee-factor ingeschakeld</span>
            </v-tooltip>
            <v-btn
              v-if="item.status == 1"
              rounded
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{ name: 'AdminUsersDetail', params: { userId: item.id } }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status == 1"
              fab
              rounded
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{ name: 'AdminUsersEdit', params: { userId: item.id } }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="user.userId != item.id && item.status == 1"
              rounded
              x-small
              depressed
              color="error"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status != 1"
              fab
              x-small
              depressed
              color="secondary"
              class="ml-1"
              @click="(activateItem = item), (activateItemDialog = true);"
            >
              <v-icon color="primary">mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          v-if="!tableUsersLoading"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableUsersFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersPaging.itemsPerPage = 25"
              :color="tableUsersPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersPaging.itemsPerPage = 50"
              :color="tableUsersPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 50"
              @click="tableUsersPaging.itemsPerPage = 100"
              :color="tableUsersPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableUsersPaging.pageCount > 1"
              v-model="tableUsersPaging.page"
              :length="tableUsersPaging.pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableUsersFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-gebruikers-export'"
                :sheetname="'Gebruikers'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.fullName }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.fullName }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Activate dialog starts here -->
    <v-dialog
      v-model="activateItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.activateItem.fullName }} activeren?</v-card-title>
        <v-card-text>Weet je zeker dat je {{ this.activateItem.fullName }} wil activeren?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(activateItemDialog = false), (activateItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="activateItemUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';
import router from '../../../router/router';

export default {
  name: 'AdminUsers',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: true,
        },
      ],
      users: [],
      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { title: 'naam', key: 'fullName' },
        { title: 'e-mailadres', key: 'email' },
        { title: 'admin (medewerker DIP)', key: 'isSuperUser' },
        { title: 'api', key: 'api' },
        { title: 'actief', key: 'statusString' },
        { title: 'mails', key: 'mails' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableUsersFilter: {
        name: '',
        email: '',
        status: 1,
      },
      tableUsersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableUsersFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'voornaam', field: 'firstName' },
        { label: 'achternaam', field: 'lastName' },
        { label: 'e-mail', field: 'email' },
        { label: 'admin (medewerker DIP)', field: 'isSuperUser' },
        { label: 'type', field: 'organisationType' },
        { label: 'organisatie', field: 'organisationName' },
        { label: 'plaats', field: 'placeName' },
        { label: 'rol', field: 'userRole' },
        { label: 'actief', field: 'statusString' },
        { label: 'contracten', field: 'contractMails' },
        { label: 'subsidies', field: 'subventionMails' },
        { label: 'voorstellingen', field: 'performanceMails' },
        { label: 'producties', field: 'productionMails' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      activateItemDialog: false,
      activateItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    this.tableUsersFilter = this.getFilter(this.$options.name);

    this.getUsers();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminUsersDetail',
        params: { userId: row.id },
      });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.users = response.data.result.map(user => ({
            ...user,
            statusString: user.status == 1 ? 'Ja' : 'Nee',
            isSuperUser: user.isSuperUser ? 'Ja' : 'Nee',
            api: user.api ? 'Ja' : 'Nee',
            contractMails: user.receiveContractMails ? 'ja' : 'nee',
            performanceMails: user.receivePerformanceMails ? 'ja' : 'nee',
            productionMails: user.receiveProductionMails ? 'ja' : 'nee',
            subventionMails: user.receiveSubventionMails ? 'ja' : 'nee',
            mails: [
              user.receiveContractMails ? 'contracten' : '',
              user.receivePerformanceMails ? 'voorstellingen' : '',
              user.receiveProductionMails ? 'producties' : '',
              user.receiveSubventionMails ? 'subsidies' : ''
            ].filter(Boolean).join(', ')
          }));

          this.tableUsersFiltered = this.users;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableUsersPaging,
          });

          this.tableUsersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableUsersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.fullName).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.email).indexOf(cleanWords(vm.tableUsersFilter.email)) !== -1 &&
          (user.status == vm.tableUsersFilter.status || vm.tableUsersFilter.status == '')
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.email = '';
      this.tableUsersFilter.status = 1;
      this.tableUsersFilterDialog = false;
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/Delete', {
        id: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersLoading = true;
            this.getUsers();

            this.snackbarText = this.deleteItem.fullName + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    activateItemUser: function () {
      ApiService.put('/Users/Activate', {
        id: this.activateItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            router.push({
              name: 'AdminUsersDetail',
              params: { userId: this.activateItem.id },
            });
            this.activateItem = '';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableUsersPaging,
    });
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>